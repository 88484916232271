/**
 * @typedef {Object} AnnotationLayerBuilderOptions
 * @property {HTMLDivElement} pageDiv
 * @property {PDFPage} pdfPage
 * @property {string} imageResourcesPath - (optional) Path for image resources,
 *   mainly for annotation icons. Include trailing slash.
 * @property {boolean} renderInteractiveForms
 * @property {IPDFLinkService} linkService
 * @property {DownloadManager} downloadManager
 * @property {IL10n} l10n - Localization service.
 * @property 
 */

import PDFJS from 'pdfjs-dist';
import { NullL10n } from 'pdfjs-dist/lib/web/ui_utils';

export class AnnotationLayerBuilder {

    /**
     * @param {AnnotationLayerBuilderOptions} options
     */
    constructor({
        pageDiv,
        pdfPage,
        linkService,
        downloadManager,
        imageResourcesPath = "",
        renderInteractiveForms = false,
        l10n = NullL10n,
        pdfViewer,
    }) {
        this.pageDiv = pageDiv;
        this.pdfPage = pdfPage;
        this.linkService = linkService;
        this.downloadManager = downloadManager;
        this.imageResourcesPath = imageResourcesPath;
        this.renderInteractiveForms = renderInteractiveForms;
        this.l10n = l10n;
        this.div = null;
        this._cancelled = false;
        this._pdfViewer = pdfViewer;
        this.listInternalsAnnotations = {};
        this.listGroupsAnnotations = {};
    }
    /**
     * @param {PageViewport} viewport
     * @param {string} intent (default value is 'display')
     */

    render(viewport, intent = 'display') {
        this.pdfPage.getAnnotations({ intent, }).then(annotations => {
            if (this._cancelled) {
                return;
            }

            let parameters = {
                viewport: viewport.clone({ dontFlip: true, }),
                div: this.div,
                annotations,
                page: this.pdfPage,
                imageResourcesPath: this.imageResourcesPath,
                renderInteractiveForms: this.renderInteractiveForms,
                linkService: this.linkService,
                downloadManager: this.downloadManager,
            };
            if (this.div) {
                // If an annotationLayer already exists, refresh its children's
                // transformation matrices.
                PDFJS.AnnotationLayer.update(parameters);
            } else {
                // Create an annotation layer div and render the annotations
                // if there is at least one annotation.
                if (annotations.length === 0) {
                    return;
                }
                this.div = document.createElement('div');
                this.div.className = 'annotationLayer';
                this.pageDiv.appendChild(this.div);
                parameters.div = this.div;

                PDFJS.AnnotationLayer.render(parameters);
                this.l10n.translate(this.div);
            }
            //SET EVENTS
            if (this.setEvents()) {
                //SET VALUES
                this.setValuesForInternalsAnnotations();
            }
        });
    }

    setValuesForInternalsAnnotations() {
        for (const id in this._pdfViewer.annotationController._annotations) {
            let annotation = this._pdfViewer.annotationController._annotations[id];
            if (annotation.annotation.type !== "internalAnnotation") {
                continue;
            }
            
            if (typeof (this.listInternalsAnnotations[id]) == 'undefined') {
                console.log('notdomelementoninternalannotation', id);
                continue;
            }
            if (!annotation.annotation.target
                || !annotation.annotation.target.selector) {
                console.log('nottargetorselectoroninternalannotation', id);
                continue;
            }
            switch (annotation.annotation.target.selector.type) {
                case 'checkbox':
                    this.listInternalsAnnotations[id].domElement.childNodes[0].checked = annotation.annotation.target.selector.checked;
                default:
                    this.listInternalsAnnotations[id].domElement.childNodes[0].value = annotation.annotation.target.selector.value;
            }
        }
    }

    cancel() {
        this._cancelled = true;
    }

    hide() {
        if (!this.div) {
            return;
        }
        this.div.setAttribute('hidden', 'true');
    }
    setEvents() {
        if (!this.pageDiv.childNodes[0]) {
            console.log('noannotationforrenderer');
            return false;
        }
        this.div = this.pageDiv.childNodes[0];
        for (var c = 0; c < this.div.childNodes.length; c++) {
            var id = this.div.childNodes[c].getAttribute('data-annotation-id');
            this.listInternalsAnnotations[id] = {
                domElement: this.div.childNodes[c]
            };
            if (this.div.childNodes[c].firstChild) {
                var groupName = this.div.childNodes[c].firstChild.getAttribute('name');

                //SET GROUP NAME
                if (groupName) {
                    if (!this.listGroupsAnnotations[groupName]) {
                        this.listGroupsAnnotations[groupName] = {};
                    }
                    this.listGroupsAnnotations[groupName][id] = {
                        domElement: this.div.childNodes[c]
                    };
                    this.listInternalsAnnotations[id]['groupName'] = groupName;
                }
            }
            this.setEventOnAnnotation(this.div.childNodes[c], id);
        }
        return true;
    }

    setEventOnAnnotation(container, id) {
        var tagName = container.childNodes[0].tagName.toLowerCase();
        var externalId = id;
        //STOP MENU COMMENT
        /*container.childNodes[0].addEventListener('mousedown', evt => {
            evt.stopPropagation();
            console.log('ok0');
           console.log(container);
        });*/
      
        /*container.childNodes[0].addEventListener('mouseup', evt => {
            evt.stopPropagation();
            console.log('ok2');
        });*/
        switch (tagName) {
            case 'textarea':
                var type = container.childNodes[0].type.toLowerCase();
                container.childNodes[0].addEventListener('keyup', (evt) => {
                    evt.stopPropagation();
                    this.setAnnotation({ playerPDF: this._pdfViewer, tagName: tagName, type: type, externalId: externalId, value: container.childNodes[0].value });
                });
                break;
            case 'input':
            default:
                var type = container.childNodes[0].type.toLowerCase();
                switch (type) {
                    case 'checkbox':
                        container.childNodes[0].addEventListener('click', (evt) => {
                            evt.stopPropagation();
                            this.setAnnotation({ playerPDF: this._pdfViewer, tagName: tagName, type: type, externalId: externalId, value: "", checked: container.childNodes[0].checked });
                            if (this.listInternalsAnnotations[id].groupName) {
                                var groupName = this.listInternalsAnnotations[id].groupName;
                                if (this.listGroupsAnnotations[groupName]) {
                                    for (var checkId in this.listGroupsAnnotations[groupName]) {
                                        if (checkId == externalId) {
                                            continue;
                                        }
                                        var otherContainer = this.listGroupsAnnotations[groupName][checkId].domElement;
                                        if (!otherContainer.childNodes[0].checked) {
                                            continue;
                                        }
                                        var otherTagName = otherContainer.childNodes[0].tagName.toLowerCase();
                                        var otherType = otherContainer.childNodes[0].type.toLowerCase();
                                        otherContainer.childNodes[0].checked = false;
                                        this.setAnnotation({ playerPDF: this._pdfViewer, tagName: otherTagName, type: otherType, externalId: checkId, value: "", checked: false });
                                    }
                                }
                            }

                        });

                        break;
                    case 'text':
                        container.childNodes[0].addEventListener('keyup', (evt) => {
                            evt.stopPropagation();
                            this.setAnnotation({ playerPDF: this._pdfViewer, tagName: tagName, type: type, externalId: externalId, value: container.childNodes[0].value });
                        });
                        break;
                }
                break;
        }

    }

    setAnnotation({ playerPDF: playerPDF, tagName: tagName, type: type, externalId: externalId, value: value, checked: checked = null }) {
        playerPDF.createInternalAnnotation({ type: type, id: externalId, tagName: tagName, value: value, checked: checked });
    }
}