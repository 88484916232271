import PDFJS from 'pdfjs-dist';
import { EventBus, NullL10n } from 'pdfjs-dist/lib/web/ui_utils';
import { PDFFindController } from 'pdfjs-dist/lib/web/pdf_find_controller';
import { TextLayerBuilder } from 'pdfjs-dist/lib/web/text_layer_builder';
import { PDFOutlineViewer } from 'pdfjs-dist/lib/web/pdf_outline_viewer';
import './translator';
import { AnnotationLayerBuilder } from './AnnotationLayerBuilder';
import { Annotation, AnnotationController } from './AnnotationController';
import LinkService from './LinkService';
import '../lib/intkn-edit-toolbar';
import '../lib/intkn-video';
import './selectionToolbar';
import './colorToolbar';


// NOTE: Importing this module is necessary as PDFJS relies on this polyfill
// but for some reason does not import it itself
import 'core-js/modules/es.promise.all-settled';

PDFJS.GlobalWorkerOptions.workerSrc = "./pdf.worker.bundle.js";

class PDFViewer extends HTMLElement {

	static get observedAttributes() {
		return [
			'interface-type',
			'keep-canvas',
			'navigation-style',
			'nb-pages-loaded',
			'render-type',
			'resolution-factor',
			'allow-annotations',
			'spread-mode',
			'url',
			'download-policy',
			'navigation-buttons'
		];
	}

	set outlinebar(outlinebar) {
		this._outlinebar = outlinebar;
	}

	set actionbar(actionbar) {
		this._actionbar = actionbar;
	}

	constructor() {
		super();
		this.container;
		this._translator = new Translator();
		this.pages = [];
		this.numPages = 0;
		this.renderedPages = []
		this.lastPage;
		this.currentPage = 1;
		this.id;
		this.url;
		this.renderType = 'canvas';
		this.interfaceType = 'web'
		this.resolutionFactor = 3;
		this.downloadPolicy = false;
		this.navigationButtons = false;
		this.scroller;
		this.zoom = false;
		this.wheelAction = "zoom";
		this.nbPagesLoaded = 5;
		this.navigationStyle = 'swipe';
		this.spreadMode = 'single';
		this.pdfObject;
		this.keepCanvas = true;
		this.canvas = document.createElement('canvas');
		this.canvasAnnotation = document.createElement('canvas');
		this._evtPointerCache = [];
		this._prevDiff = -1;
		this.zoomStart = false;
		// this.editorMenu = false;
		this.scrollContainer = document.documentElement;
		this.loaded = false;
		document.body.classList.add('class', 'bodyPdf');
		this._annotationsAllowed = true;
		this.annotationController = new AnnotationController(this);
		this.linkService;
		this.pdfFindController;

		this.scale = 1;  // scale of the image
		this.xLast = 0;  // last x location on the screen
		this.yLast = 0;  // last y location on the screen
		this.xImage = 0; // last x location on the image
		this.yImage = 0; // last y location on the image

		// window.addEventListener("message", receiveMessageToInterface, false);

		this.isMobileApp = false;

		this.VIEW_WIDTH_INDEX = 2;
		this.VIEW_HEIGHT_INDEX = 3;
		this._scrollDimension = this.VIEW_HEIGHT_INDEX;
	}

	attributeChangedCallback(name, oldValue, newValue) {
		switch (name) {
			case 'url':
				this.url = newValue;
				this.load(this.url);
				break;
			case 'render-type':
				this.setRenderType(newValue);
				break;
			case 'resolution-factor':
				this.setResolutionFactor(newValue);
				break;
			case 'navigation-style':
				this.setNavigationStyle(newValue);
				break;
			case 'interface-type':
				this.setInterfaceType(newValue);
				break;
			case 'download-policy':
				this.setDownloadPolicy(newValue);
				break;
			case 'spread-mode':
				this.setSpreadMode(newValue);
				break;
			case 'nb-pages-loaded':
				this.setNbPagesLoaded(newValue);
				break;
			case 'keep-canvas':
				this.setKeepCanvas(newValue);
				break;
			case 'allow-annotations':
				this.setAllowAnnotations(newValue);
				break;
			case 'navigation-buttons':
				this.setNavigationButtons(newValue);
				break;
		}
	}

	connectedCallback() {
		this.innerHTML = `<div class="loadCover"></div>
			<div class="viewer" id="viewer"></div>`;
		this.container = this.querySelector('.viewer');
		this.loader = this.querySelector('.loadCover');


		// if (this.url) {
		// 	this.load(this.url);
		// }
	}

	setData(element) {
		this.data = element;
		this.id = element.id;
	};

	setInterfaceType(type) {
		//web, iosApp, androidApp
		this.interfaceType = type;
		if (this.interfaceType === "iosApp" || this.interfaceType === "androidApp") {
			this.isMobileApp = true;
		}
	}

	setDownloadPolicy(value) {
		this.downloadPolicy = value;
	}

	setNavigationButtons(value) {
		this.navigationButtons = value;
	}

	setNavigationStyle(navigationStyle) {
		this.navigationStyle = navigationStyle;

		if (this.navigationStyle == 'vertical') {
			this._scrollDimension = this.VIEW_HEIGHT_INDEX;
		} else if (this.navigationStyle == 'horizontal') {
			this._scrollDimension = this.VIEW_WIDTH_INDEX;
		}

		if (this.loaded) {
			for (var i = 0; i < this.pages.length; i++) {
				this._removePage(i);
			}
			this.keepCanvas = this.canUseSingleCanvas();
			this.lastPage = null;
			this.initPages();
			this._removeAllPageDOM();
			this._initializeDom();
			this.renderPageCurrentContext();
		}
	}
	setRenderType(renderType) {
		this.renderType = renderType.toLowerCase();
	}
	setResolutionFactor(resFactor) {
		this.resolutionFactor = resFactor;
	}
	setContainer(container) {
		this.container = container;
		this.container.style.zoom = 1;
	}
	setKeepCanvas(keep) {
		this.keepCanvas = keep;
	}
	setAllowAnnotations(allowed) {
		this._annotationsAllowed = (allowed == 'true');
	}

	/**
	 * The spread mode defines how many pages can be on the same column.
	 * TODO: Convert spreadMode to number and scale pages on window resize.
	 * 
	 * @param {string} spreadMode single or double
	 */
	setSpreadMode(spreadMode) {
		if (spreadMode != 'single' && spreadMode != 'double') {
			return;
		}

		if (this.navigationStyle == 'vertical' && spreadMode == 'double') {
			this.container.classList.add('viewerVerticalSpread');
		} else {
			this.container.classList.remove('viewerVerticalSpread');
		}

		this.spreadMode = spreadMode;
		// We cannot keep the canvas if we have more than one page displayed
		this.keepCanvas = this.canUseSingleCanvas();
		this.renderPageCurrentContext();
		var playerPdf = this;
		setTimeout(function (evt) {
			playerPdf.setPerfectScale(playerPdf.pages[playerPdf.currentPage].pageView);
		}, 200)

	}

	canUseSingleCanvas() {
		return this.spreadMode == 'single' && this.navigationStyle == 'swipe';
	}

	_getPagesOffsets() {
		var offsetStart = this.currentPage - this.nbPagesLoaded;
		var offsetEnd = this.currentPage + this.nbPagesLoaded;

		return {
			start: Math.max(offsetStart, 1),
			end: Math.min(offsetEnd, this.pdfObject.numPages)
		}
	}

	_setContainerAttrs() {
		// Remove styles if we are changing navigation style
		this.container.classList.remove('viewerVertical');
		this.container.classList.remove('viewerHorizontal');
		this.container.classList.remove('viewerSwipe');

		//TOOLBARS

		this.colorToolbar = document.createElement('intkn-color-toolbar');
		this.colorToolbar.classList.add('colorToolbar');
		//console.log(this.colorToolbar);
		this.appendChild(this.colorToolbar);

		this.toolbar = document.createElement('intkn-selection-toolbar');
		this.toolbar.classList.add('selectionToolbar');
		this.toolbar.classList.add('selectionToolbarDefault');

		//PRIMARY ACTION
		let bookmark = document.createElement('button');
		bookmark.innerHTML = '<img src="/office/ressources/img/intkn_bookmark.svg">'
		bookmark.title = 'bookmark';
		bookmark.classList.add('toolBarBookmark');
		bookmark.classList.add('disabledAction');
		this.toolbar.appendChild(bookmark);
		let textTools = document.createElement('button');
		textTools.innerHTML = '<img src="/office/ressources/img/intkn_texttools.svg">'
		textTools.title = 'texttools';
		textTools.classList.add('toolBarTextTools');
		textTools.classList.add('disabledAction');
		this.toolbar.appendChild(textTools);
		//TEXT TOOLS ACTIONS
		let comment = document.createElement('button');
		comment.innerHTML = '<img src="/office/ressources/img/intkn_bubble.svg">'
		comment.title = 'comment';
		comment.classList.add('toolBarComment');
		comment.classList.add('disabledAction');
		this.toolbar.appendChild(comment);

		let highlight = document.createElement('button');
		highlight.classList.add('toolBarHighlight');
		highlight.classList.add('disabledAction');
		highlight.innerHTML = '<img src="/office/ressources/img/intkn_highlight.svg">'
		highlight.title = 'highlight';
		this.toolbar.appendChild(highlight);

		let copy = document.createElement('button');
		copy.innerHTML = '<img src="/office/ressources/img/intkn_copy_gray.svg">'
		copy.title = 'copy';
		copy.classList.add('toolBarCopy');
		copy.classList.add('disabledAction');
		this.toolbar.appendChild(copy);

		let action = document.createElement('button');
		action.title = 'Ajouter une annotation';
		action.classList.add('toolBarAction');
		this.toolbar.appendChild(action);

		this.toolbar.buttons = {
			highlight: highlight,
			comment: comment,
			copy: copy,
			action: action,
			bookmark: bookmark,
			textTools: textTools,
		}

		action.addEventListener('click', evt => {
			evt.stopPropagation();
			var selection = document.getSelection();
			if (selection
				&& !selection.isCollapsed) {
				if (this.toolbar.classList.contains('selectionToolbarDefault')) {
					this.toolbar.setSelectedTextActions();
					this.colorToolbar.setTextColorActions();
					return;
				}
				this.toolbar.setDefaultAction();
				return;
			}
			if (this.toolbar.actionMode !== 'primary') {
				this.colorToolbar.setTextColorActions();
				this.toolbar.setPrimaryAction();
				return;
			}
			this.colorToolbar.setDefaultAction();
			this.toolbar.setDefaultAction();
		});

		textTools.addEventListener('click', evt => {
			evt.stopPropagation();
			this.toolbar.setSelectedTextActions();
			this.colorToolbar.setTextColorActions();
		});

		bookmark.addEventListener('click', evt => {
			evt.stopPropagation();
			this.toggleBookmark();
		});

		highlight.addEventListener('click', evt => {
			evt.stopPropagation();
			this.colorToolbar.setTextColorActions();
			var selection = document.getSelection();
			if (!selection) {
				return;
			}
			let index = this._getPageIndexSelection(selection);
			let annotation = new Annotation();
			annotation.setType('highlight');
			annotation.setTargetId(this.id);
			annotation.setColor(this.colorToolbar.textSelectedColor)
			annotation.setSelector(selection);
			annotation.setPage(index);

			this.annotationController.add(annotation, (evt) => {
				// TODO: Inject callback, do not set it here as it differs
				// for each integration
				evt.stopPropagation();
				let event = new CustomEvent('pdfreader-display-popup', {
					bubbles: true,
					detail: {
						type: 'edit-annotation',
						element: annotation
					}
				});
				this.container.dispatchEvent(event);
				this.toolbar.setDefaultAction();
				this.colorToolbar.setDefaultAction();
			});

			var event = new CustomEvent('pdfreader-add-note', {
				bubbles: true,
				detail: {
					...annotation,
					externalId: annotation.id
				}
			});
			this.container.dispatchEvent(event);

			selection.removeAllRanges();
			this.toolbar.setDefaultAction();
			this.colorToolbar.setDefaultAction();
		});

		comment.addEventListener('click', evt => {
			evt.stopPropagation();
			var selection = document.getSelection();
			if (!selection) {
				return;
			}
			let index = this._getPageIndexSelection(selection);
			let annotation = new Annotation();
			annotation.setType('highlight');
			annotation.setTargetId(this.id);
			annotation.setSelector(selection);
			annotation.setPage(index);

			let event = new CustomEvent('pdfreader-display-popup', {
				bubbles: true,
				detail: {
					type: 'create-annotation',
					element: annotation
				}
			});
			this.container.dispatchEvent(event);

			selection.removeAllRanges();
		});

		copy.addEventListener('click', evt => {
			evt.stopPropagation();
			var selection = document.getSelection();
			if (!selection) {
				return;
			}
			// TODO: Remove weird characters found in cataropro books
			document.execCommand("copy");
			selection.removeAllRanges();
		});

		this.appendChild(this.toolbar);

		//END TOOLBAR

		switch (this.navigationStyle) {
			case 'vertical':
				this.container.classList.add('viewerVertical');
				break;
			case 'horizontal':
				this.container.classList.add('viewerHorizontal');
				break;
			case 'swipe':
				this.container.classList.add('viewerSwipe');
				break;
		}

		this.eventBus.on('updatetextlayermatches', evt => {
			// NOTE: Sending evt._pageContents will send all page content, is it
			// not better to only send matching text with some offset before and
			// after ?
			var result = {
				pageMatches: evt.source._pageMatches,
				pageContents: evt.source._pageContents,
				matchesCountTotal: evt.source._matchesCountTotal,
				rawQuery: evt.source._rawQuery,
				normalizedQuery: evt.source._normalizedQuery
			}

			var event = new CustomEvent('pdfreader-find-text', {
				bubbles: true,
				detail: result
			});
			this.container.dispatchEvent(event);
		});

		this.addEventListener("wheel", evt => {
			evt.stopPropagation();

			if (evt.ctrlKey) {
				// Prevent browser zoom
				// TODO: Handle zoom in/out
				event.preventDefault();
				let zoom = this.zoom;
				let dif = event.deltaY * -0.005;
				if (dif < 0) {
					dif = -0.1;
					if (!isDesktop()) {
						dif = -0.3;
					}
				} else if (dif > 0) {
					dif = 0.1;
					if (!isDesktop()) {
						dif = 0.3;
					}
				} else {
					return;
				}
				zoom += dif;
				zoom = Math.min(Math.max(.4, zoom), 4);
				this.setFitPage(zoom);
			}
		});
		this.container.addEventListener('click', evt => {
			evt.stopPropagation();
			var selection = document.getSelection();

			if (selection.isCollapsed && this.toolbar) {
				this.toolbar.setDefaultAction();
			}
			return false;
		});

		/*this.container.addEventListener('mousedown', evt => {
			evt.stopPropagation();
			return false;
		});*/
		this.container.addEventListener('contextmenu', evt => {
			evt.stopPropagation();
			evt.preventDefault();
			return false;
		});
		this.container.addEventListener('mouseup', evt => {
			evt.stopPropagation();
			if (!this._annotationsAllowed) {
				return;
			}
			var selection = document.getSelection();
			var pageIndex = this._getPageIndexSelection(selection);
			if (this.toolbar) {
				this.toolbar.setDefaultAction();
				this.colorToolbar.setDefaultAction();
			}

			if (!pageIndex ||
				this.interfaceType !== 'web' ||
				selection.toString().trim() == '' ||
				selection.isCollapsed) {
				return;
			}

			if (this.toolbar) {
				this.toolbar.setSelectedTextActions();
			}
			if (this.colorToolbar) {
				this.colorToolbar.setTextColorActions();
			}

		});
		if (isDesktop()) {
			return;
		}
		this._initZoomGesture();
		this.container.addEventListener('touchend', evt => {
			evt.stopPropagation();
			if (!this._annotationsAllowed) {
				return;
			}
			var selection = document.getSelection();
			var pageIndex = this._getPageIndexSelection(selection);
			if (this.toolbar) {
				this.toolbar.setDefaultAction();
				this.colorToolbar.setDefaultAction();
			}

			if (!pageIndex ||
				this.interfaceType !== 'web' ||
				selection.toString().trim() == '' ||
				selection.isCollapsed) {
				return;
			}

			if (this.toolbar) {
				this.toolbar.setSelectedTextActions();
			}
			if (this.colorToolbar) {
				this.colorToolbar.setTextColorActions();
			}

		});
	}

	addAnnotation(annotation) {
		this.annotationController.add(annotation, (evt) => {
			// Adds default behavior for annotation
			evt.stopPropagation();
			let event = new CustomEvent('pdfreader-display-popup', {
				bubbles: true,
				detail: {
					type: 'edit-annotation',
					element: annotation
				}
			});
			this.container.dispatchEvent(event);
		});

		let event = new CustomEvent('pdfreader-add-note', {
			bubbles: true,
			detail: {
				...annotation,
				externalId: annotation.id,
			}
		});
		this.container.dispatchEvent(event);
	}

	setToggleOutlines() {
		this._outlinebar.classList.toggle('displayNone');
	}
	_initZoomGesture() {
		this.container.addEventListener('pointerdown', (evt) => {
			// L'événement pointerdown signale le début d'une interraction de toucher.
			// L'événement est mis en cache pour prendre en charge les gestes à 2 doigts
			this._evtPointerCache.push(evt);
		});
		this.container.addEventListener('pointermove', (evt) => {
			// Cette fonction implémente la détection du mouvement horizontal pincer/zoomer.
			//
			// Si la distance entre les deux pointeurs augmente (zoomer), 
			// l'arrière-plan de l'élément cible est changé en "pink" et si la 
			// distance diminue (dezoomer), la couleur est changée en "lightblue".
			//
			// Cette fonctionne définie la bordure de l'élément cible à "dashed" pour indiquer
			// visuellement que la cible du pointeur a reçu un événement de déplacement.

			// Trouve le pointeur en cours dans le cache et le met à jour avec cet événement
			for (let i = 0; i < this._evtPointerCache.length; i++) {
				if (evt.pointerId == this._evtPointerCache[i].pointerId) {
					this._evtPointerCache[i] = evt;
					break;
				}
			}

			// Si deux pointeurs sont utilisés, vérifie le geste de pincement
			if (this._evtPointerCache.length == 2) {
				// Calcule la distance entre les deux pointeurs
				let curDiffX = Math.abs(this._evtPointerCache[0].clientX - this._evtPointerCache[1].clientX);
				let curDiffY = Math.abs(this._evtPointerCache[0].clientY - this._evtPointerCache[1].clientY);
				let curDiff = curDiffY;
				if (Math.abs(curDiffX) > Math.abs(curDiffY)) {
					curDiff = curDiffX;
				}

				if (this._prevDiff > 0) {
					if (Math.abs(curDiff - this._prevDiff) < 0.5) {
						return;
					}
					if (curDiff > this._prevDiff) {
						// La distance entre les deux pointeurs a augmenté
						//console.log("Pinch moving OUT -> Zoom in", evt);
						let dif = curDiff - this._prevDiff;
						let zoom = this.zoom;
						if (!isDesktop()) {
							zoom += 0.3;
						} else {
							zoom += 0.1;
						}

						zoom = Math.min(Math.max(.4, zoom), 4);
						this.setFitPage(zoom);
					}
					if (curDiff < this._prevDiff) {
						// La distance entre les deux pointeurs a diminué
						//console.log("Pinch moving IN -> Zoom out",evt);
						let zoom = this.zoom;
						if (!isDesktop()) {
							zoom -= 0.3;
						} else {
							zoom -= 0.1;
						}
						zoom = Math.min(Math.max(.4, zoom), 4);
						this.setFitPage(zoom);
					}
				}

				// Met en cache la distance pour les événements suivants
				this._prevDiff = curDiff;
			}
		});

		this.container.addEventListener('pointerup', (evt) => {
			// Retire ce pointeur du cache et rétablit l'arrière-plan et
			// et bordure de la cible
			this._remove_event(evt);
			// Si le nombre de pointeurs restant est inférieur à deux, remet à zéro la différence
			if (this._evtPointerCache.length < 2) this._prevDiff = -1;
		});
		this.container.addEventListener('pointercancel', (evt) => {
			// Retire ce pointeur du cache et rétablit l'arrière-plan et
			// et bordure de la cible
			this._remove_event(evt);
			// Si le nombre de pointeurs restant est inférieur à deux, remet à zéro la différence
			if (this._evtPointerCache.length < 2) this._prevDiff = -1;
		});
		this.container.addEventListener('onpointerout', (evt) => {
			// Retire ce pointeur du cache et rétablit l'arrière-plan et
			// et bordure de la cible
			this._remove_event(evt);
			// Si le nombre de pointeurs restant est inférieur à deux, remet à zéro la différence
			if (this._evtPointerCache.length < 2) this._prevDiff = -1;
		});
		this.container.addEventListener('pointerleave', (evt) => {
			// Retire ce pointeur du cache et rétablit l'arrière-plan et
			// et bordure de la cible
			this._remove_event(evt);
			// Si le nombre de pointeurs restant est inférieur à deux, remet à zéro la différence
			if (this._evtPointerCache.length < 2) this._prevDiff = -1;
		});
	}
	_remove_event(evt) {
		// Supprime l'événement du cache
		for (let i = 0; i < this._evtPointerCache.length; i++) {
			if (this._evtPointerCache[i].pointerId == evt.pointerId) {
				this._evtPointerCache.splice(i, 1);
				break;
			}
		}
	}
	/**
	 * Return the max scale the page can have while still fitting the window
	 *
	 * @param {*} viewport
	 * @returns
	 */
	_getMaxPageRatio(width, height) {
		var maxScaleWidth = window.innerWidth / width;
		var maxScaleHeight = window.innerHeight / height;

		return Math.min(maxScaleHeight, maxScaleWidth);
	}

	/**
	 * @param {number} [refreshIndex] Index of the page
	 */
	refreshPageAnnotations(refreshIndex) {
		if (!refreshIndex) {
			var refreshIndex = this.currentPage;
		}
		this.annotationController.refresh(refreshIndex);
	}

	_initializeDom() {
		this._setContainerAttrs();

		// Only do if not appended
		if (this._actionbar) {
			this._createActionBar();
		}
		this._createSliderBar();
		this._createShortcutKeyBoard();
		if (this.downloadPolicy == 'true') {
			this._createDownloadButton();
		}

		//this._intitScrollAndScale();
		if (this.navigationStyle == 'vertical' || this.navigationStyle == 'horizontal') {
			var scrollDirection = this.navigationStyle == 'vertical' ? 'scrollTop' : 'scrollLeft';
			window.addEventListener('scroll', _evt => {
				var scrollStart = this.scrollContainer[scrollDirection];
				this.computeCurrentViewingPage(scrollStart);

				if (this.lastPage != this.currentPage) {
					this.renderPageCurrentContext();
				}
			});
		}
		this.eventBus.on('textlayerrendered', page => {
			if (!this.pages[page.pageNumber]) {
				return;
			}
			this.refreshPageAnnotations(page.pageNumber);
		});

		if (!this._annotationsAllowed) {
			document.body.onselectionchange = evt => {
				this.handleSelection();
			};
		}
	}

	initPages() {
		var _setView = (pageNumber, callback) => {
			this.pdfObject.getPage(pageNumber).then(pagePDF => {
				this.pages[pageNumber].pageView = pagePDF._pageInfo.view;
				this.setPerfectScale(pagePDF._pageInfo.view);
				if (this.navigationStyle != 'swipe') {
					var viewport = pagePDF.getViewport({ scale: this.scale });
					var pageContainer = this._createPageContainer(viewport, pagePDF._pageIndex);
					this.container.appendChild(pageContainer);
				}
				this.pages[pageNumber].pageView = pagePDF._pageInfo.view;
				this.pages[pageNumber].pageContainer = pageContainer;

				//last page view loaded
				if (pageNumber == this.pdfObject.numPages) {
					callback();
				}
			});
		}

		return new Promise((resolve, reject) => {
			var totalPages = this.pdfObject.numPages;
			for (var pageNb = 1; pageNb <= totalPages; pageNb++) {
				this.pages[pageNb] = {
					pageView: false,
					pageDomElement: false,
					canvasDomElement: false,
					canvasAnnotationDomElement: false,
					editorDomElement: false,
					textDomElement: false,
					state: false
				};
				//get the page size
				_setView(pageNb, resolve);
			}
		});
	}

	/**
	 * @param {*} viewport
	 * @returns {Element}
	 */
	_createPageContainer(viewport, pageIndex) {
		var pageContainer = document.createElement('div');
		pageContainer.classList.add('pagePDF');

		if (this.navigationStyle == 'vertical') {
			pageContainer.classList.add('pagePDFVertical');
		} else if (this.navigationStyle == 'horizontal') {
			pageContainer.classList.add('pagePDFHorizontal');
		}

		pageContainer.setAttribute('data-page-number', pageIndex);
		// Set the canvas drawing width and height, width and height are 
		// sometimes NaN, in this case we use the viewBox size properties...
		if (viewport.width && viewport.height) {
			pageContainer.style.width = viewport.width + 'px';
			pageContainer.style.height = viewport.height + 'px';
		} else {
			pageContainer.style.width = viewport.viewBox[this.VIEW_WIDTH_INDEX] + 'px';
			pageContainer.style.height = viewport.viewBox[this.VIEW_HEIGHT_INDEX] + 'px';
		}
		return pageContainer;
	}

	_createCanvasContainer(pageContainer) {
		var canvasContainer = document.createElement('div');
		canvasContainer.classList.add('canvasContainerPDF');
		pageContainer.appendChild(canvasContainer);
		return canvasContainer;
	}

	_createCanvas(viewport, container) {
		var canvas = this.keepCanvas ? this.canvas : document.createElement('canvas');
		canvas.setAttribute('class', 'canvasPDF');
		canvas.style.opacity = '0';
		// Set the canvas drawing width and height, width and height are 
		// sometimes NaN, in this case we use the viewBox size properties...
		if (viewport.width && viewport.height) {
			canvas.width = viewport.width * this.resolutionFactor;
			canvas.height = viewport.height * this.resolutionFactor;
		} else {
			canvas.width = viewport.viewBox[this.VIEW_WIDTH_INDEX] * this.resolutionFactor;
			canvas.height = viewport.viewBox[this.VIEW_HEIGHT_INDEX] * this.resolutionFactor;
		}
		container.appendChild(canvas);
		return canvas;
	}

	_createAnnotationCanvas(viewport, container) {
		var canvas = this.keepCanvas ? this.canvasAnnotation : document.createElement('canvas');
		canvas.setAttribute('class', 'canvasPDF');
		canvas.setAttribute('class', 'canvasAnnotation');
		canvas.style.opacity = '0';
		if (viewport.width && viewport.height) {
			canvas.width = viewport.width * this.resolutionFactor;
			canvas.height = viewport.height * this.resolutionFactor;
		} else {
			canvas.width = viewport.viewBox[this.VIEW_WIDTH_INDEX] * this.resolutionFactor;
			canvas.height = viewport.viewBox[this.VIEW_HEIGHT_INDEX] * this.resolutionFactor;
		}
		container.appendChild(canvas);
		return canvas;
	}

	_createTextLayer(parentNode) {
		var textLayer = document.createElement('div');
		textLayer.setAttribute('class', 'textLayerPDF');
		if (this.interfaceType === "androidApp") {
			textLayer.addEventListener("click", function (evt) {
				var selection = window.getSelection();
				selection.removeAllRanges();
			})
		}
		parentNode.appendChild(textLayer);

		return textLayer;
	}
	_createAnnotationLayer(parentNode) {
		var annotationLayer = document.createElement('div');
		annotationLayer.setAttribute('class', 'annotationLayerPDF');
		parentNode.appendChild(annotationLayer);
		annotationLayer.addEventListener('mousedown', function (evt) {
			if (this.interfaceType === "web") {
				this.editorMenu.hide({});
			}
		});
		return annotationLayer;
	}
	_createEditorLayer(parentNode) {
		var editorLayer = document.createElement('div');
		editorLayer.setAttribute('class', 'editorLayerPDF');
		parentNode.appendChild(editorLayer);

		return editorLayer;
	}
	_intitScrollAndScale() {
		this.scroller = new scrollAndScale();
		this.scroller.setContainer(window);
		this.scroller.setObject(this.container);
		this.scroller.setLimits({ min: 0.4, max: 4 });
	}
	_createNavigationButtons() {
		if (this.navigationButtons != 'true' || this.nbPages < 2) {
			return;
		}
		let pdfReaderPreviousButton = document.createElement('div');
		pdfReaderPreviousButton.setAttribute('class', 'pdfReaderPreviousButton');
		this.appendChild(pdfReaderPreviousButton);
		let pdfReaderNextButton = document.createElement('div');
		pdfReaderNextButton.setAttribute('class', 'pdfReaderNextButton');
		this.appendChild(pdfReaderNextButton);
		this.buttons = {
			navigation: {
				previous: pdfReaderPreviousButton,
				next: pdfReaderNextButton
			}
		}
		pdfReaderPreviousButton.addEventListener('click', (evt) => {
			evt.stopPropagation();
			let targetPage = this.currentPage - 1;
			if (targetPage < 1) {
				return;
			}
			this.setPage(targetPage);
		});
		pdfReaderNextButton.addEventListener('click', (evt) => {
			evt.stopPropagation();
			let targetPage = this.currentPage + 1;
			if (targetPage > this.pdfObject.numPages) {
				return;
			}
			this.setPage(targetPage);
		});
	}
	_createDownloadButton() {
		let pdfReaderDownloadButton = document.createElement('button');
		pdfReaderDownloadButton.setAttribute('class', 'pdfReaderDownloadButton');
		pdfReaderDownloadButton.setAttribute('data-title-right', this._translator.getString('download'));
		let pdfReaderDownloadImg = document.createElement('img');
		pdfReaderDownloadImg.src = '/office/ressources/img/intkn_download_white.svg';
		pdfReaderDownloadButton.appendChild(pdfReaderDownloadImg);
		this.appendChild(pdfReaderDownloadButton);
		var urlAttachment = this.url.replace('inline', 'attachment');
		pdfReaderDownloadButton.addEventListener('click', (evt) => {
			evt.stopPropagation();
			window.open(urlAttachment);
			//this.setPage(targetPage);
		});
	}
	_createShortcutKeyBoard() {
		window.addEventListener("keyup", (evt) => {
			//evt.stopPropagation();
			//evt.preventDefault();
			switch (evt.key) {
				case 'ArrowUp':
					//up arrow
					break;
				case 'ArrowDown':
					//down arrow
					break;
				case 'ArrowLeft':
					//left arrow
					this.setPreviousPage();
					//pageSelector.value = this.currentPage;
					break;
				case 'ArrowRight':
					//right arrow
					this.setNextPage();
				//pageSelector.value = this.currentPage;
			}
		});
	}
	_createSliderBar() {
		let sliderPageContainer = document.createElement('div');
		sliderPageContainer.setAttribute('class', 'sliderPageContainer');
		let sliderPageSubContainer = document.createElement('div');
		sliderPageSubContainer.setAttribute('class', 'sliderPageSubContainer');
		if (isDesktop()) {
			var sliderPage = document.createElement('div');
			var sliderPageHandle = document.createElement('div');
			sliderPageHandle.setAttribute('class', 'sliderPageHandle');
			this.sliderPageHandle = sliderPageHandle;
			sliderPageSubContainer.appendChild(sliderPageHandle);
			var sliderPageHoverTitle = document.createElement('div');
			sliderPageHoverTitle.setAttribute('class', 'sliderPageHoverTitle');
			sliderPageHoverTitle.style.display = 'none';
			sliderPageSubContainer.appendChild(sliderPageHoverTitle);
		} else {
			var sliderPage = document.createElement('input');
			sliderPage.setAttribute("type", "range");
			sliderPage.setAttribute("step", "1");
			sliderPage.setAttribute("min", "1");
			sliderPage.setAttribute("max", "1");
			sliderPage.setAttribute("value", "1");
		}
		this.sliderPage = sliderPage;
		sliderPage.setAttribute('class', 'sliderPage');
		sliderPageSubContainer.appendChild(sliderPage);
		sliderPageContainer.appendChild(sliderPageSubContainer);
		this.appendChild(sliderPageContainer);
		if (isDesktop()) {
			let sliderMouseDown = false;
			sliderPage.addEventListener('mousedown', evt => {
				evt.stopPropagation();
				sliderMouseDown = true;
			});
			sliderPageContainer.addEventListener('mouseup', evt => {
				evt.stopPropagation();
				if (!sliderMouseDown) {
					return;
				}
				sliderMouseDown = false;
				this._setNewPageInSlider(evt);
			});
			sliderPageContainer.addEventListener('mousemove', evt => {
				evt.stopPropagation();
				if (!sliderMouseDown) {
					return;
				}
				let rect = this.sliderPage.getBoundingClientRect();
				let x = evt.clientX - rect.left;
				let xHandle = x - 10;
				if (xHandle < 0) {
					xHandle = 0;
				}
				if (xHandle >= rect.width - 10) {
					xHandle = rect.width - 20;
				}
				let percentage = xHandle / rect.width * 100;
				this.sliderPageHandle.style.left = `${percentage}%`;
			});

			sliderPageContainer.addEventListener('mouseleave', evt => {
				evt.stopPropagation();
				if (!sliderMouseDown) {
					return;
				}
				sliderMouseDown = false;
				this._setNewPageInSlider(evt);
			});

			sliderPage.addEventListener('mouseenter', evt => {
				evt.stopPropagation();
				sliderPageHoverTitle.style.display = '';
			});

			sliderPageContainer.addEventListener('mousemove', evt => {
				evt.stopPropagation();
				let rect = this.sliderPage.getBoundingClientRect();
				let x = evt.clientX - rect.left;
				let selectedPage = this._calcSelectedPage(evt);
				let percentage = x / rect.width * 100;
				sliderPageHoverTitle.style.left = `${percentage}%`;
				sliderPageHoverTitle.textContent = selectedPage;

			});

			sliderPageContainer.addEventListener('mouseleave', evt => {
				evt.stopPropagation();
				sliderPageHoverTitle.style.display = 'none';
			});
		} else {
			sliderPage.addEventListener('input', evt => {
				evt.stopPropagation();
				this._setNewPageInSlider(evt, sliderPage.value);
			});
			sliderPage.addEventListener('click', evt => {
				evt.stopPropagation();
				this._setNewPageInSlider(evt, sliderPage.value);
			});
		}
	}
	_setNewPageInSlider(evt, selectedPage) {
		if (!selectedPage) {
			var selectedPage = this._calcSelectedPage(evt);
		}
		this.setPageFromExternal(selectedPage);
	}
	refreshPageSlider() {
		if (!isDesktop()) {
			return;
		}
		let percentage = this.currentPage / this.numPages;
		let rect = this.sliderPage.getBoundingClientRect();
		let xHandle = (rect.width * percentage) - 10;
		if (percentage == 1) {
			xHandle = rect.width - 20;
		}
		percentage = xHandle / rect.width * 100;
		this.sliderPageHandle.style.left = `${percentage}%`;
	}
	_calcSelectedPage(evt) {
		let rect = evt.target.getBoundingClientRect();
		let x = evt.clientX - rect.left;
		let percentage = x / rect.width;
		let selectedPage = Math.round(this.numPages * percentage);
		if (selectedPage < 1) {
			selectedPage = 1;
		}
		if (selectedPage > this.numPages) {
			selectedPage = this.numPages;
		}
		return selectedPage;
	}
	// TODO: Move the actionbar outside of the viewer Custom Element
	_createActionBar() {
		//ACTION BAR
		var divBottomActions = document.createElement('div');
		divBottomActions.classList.add('editorCanvasActionBarBottom');

		var divBottomPagesAction = document.createElement('div');
		divBottomPagesAction.classList.add('editorCanvasActionBarBottom');

		//ZOOM NEGATIVE
		var zoomNegative = document.createElement('div');
		zoomNegative.classList.add('editorCanvasButtonsBottom');
		zoomNegative.classList.add('editorCanvasZoomNegative');
		zoomNegative.innerHTML = '-';
		divBottomActions.appendChild(zoomNegative);
		zoomNegative.addEventListener('click', evt => {
			this.setZoomOut(evt);
		});
		//ZOOM POSITIVE
		var zoomPositive = document.createElement('div');
		zoomPositive.classList.add('class', 'editorCanvasButtonsBottom');
		zoomPositive.classList.add('class', 'editorCanvasZoomPositive');
		zoomPositive.innerHTML = '+';
		divBottomActions.appendChild(zoomPositive);
		zoomPositive.addEventListener('click', evt => {
			this.setZoomIn(evt);
		});
		//PREVIOUS PAGE
		var previousPage = document.createElement('div');
		previousPage.classList.add('editorCanvasButtonsBottom');
		previousPage.classList.add('editorCanvasPreviousPage');
		previousPage.innerHTML = '<';
		divBottomActions.appendChild(previousPage);
		//NEXT PAGE
		var nextPage = document.createElement('div');
		nextPage.classList.add('editorCanvasButtonsBottom');
		nextPage.classList.add('editorCanvasNextPage');
		nextPage.innerHTML = '>';
		divBottomActions.appendChild(nextPage);

		//ZOOM LABEL
		var zoomLabel = document.createElement('div');
		zoomLabel.classList.add('editorCanvasZoomLabel');
		zoomLabel.innerHTML = 100 + '%';
		divBottomActions.appendChild(zoomLabel);
		this._actionbar.appendChild(divBottomActions);
		//GOTO PAGE
		var pageSelector = document.createElement('input');
		pageSelector.classList.add('editorCanvasPageSelector');
		pageSelector.value = this.currentPage;
		divBottomPagesAction.appendChild(pageSelector);
		pageSelector.addEventListener('change', _evt => {
			if (pageSelector.value == '' || isNaN(parseInt(pageSelector.value))) {
				return;
			}
			var value = parseInt(pageSelector.value);
			var targetPage = value;
			if (targetPage > this.pdfObject.numPages
				|| targetPage < 1) {
				pageSelector.value = this.currentPage;
				return;
			}
			this.setPage(targetPage);
		});
		var maxPageLabel = document.createElement('div');
		maxPageLabel.classList.add('editorCanvasLabelBottom');
		maxPageLabel.classList.add('editorCanvasMaxPageLabel');
		maxPageLabel.innerText = "sur " + this.pdfObject.numPages;
		divBottomPagesAction.appendChild(maxPageLabel);
		this._actionbar.appendChild(divBottomPagesAction);
		//fullScreen
		var divBottomPagesFullScreen = document.createElement('div');
		divBottomPagesFullScreen.classList.add('editorCanvasActionBarBottom');
		var fullScreen = document.createElement('div');
		fullScreen.classList.add('editorCanvasButtonsBottom');
		fullScreen.classList.add('editorCanvasFullScreen');
		fullScreen.addEventListener('click', function (evt) {
			if (document.fullscreenElement) {
				document.exitFullscreen();
				return;
			}
			document.documentElement.requestFullscreen();
		});
		divBottomPagesFullScreen.appendChild(fullScreen);
		this._actionbar.appendChild(divBottomPagesFullScreen);

		previousPage.addEventListener('click', evt => {
			this.setPreviousPage();
			pageSelector.value = this.currentPage;
		});
		nextPage.addEventListener('click', evt => {
			this.setNextPage();
			pageSelector.value = this.currentPage;
		});
	}

	/**
	 * @param {Number} [pageIndex]
	 * @memberof PDFViewer
	 */
	toggleBookmark(pageIndex) {
		var index = pageIndex || this.currentPage
		this.annotationController.toggleBookmark(index);
	}

	_createPageDOM(pagePDF, scale) {
		let pageContainer = this.pages[pagePDF._pageIndex + 1].pageContainer;
		let viewport = pagePDF.getViewport({ scale: scale });
		let canvasContainer = this._createCanvasContainer(pageContainer);
		let canvas = this._createCanvas(viewport, canvasContainer);
		let canvasAnnotation = this._createAnnotationCanvas(viewport, canvasContainer);
		let textLayer = this._createTextLayer(pageContainer);
		let annotationLayer = this._createAnnotationLayer(pageContainer);
		let editorLayer = this._createEditorLayer(pageContainer);
		return {
			pageContainer: pageContainer,
			canvasElement: canvas,
			canvasAnnotationElement: canvasAnnotation,
			textLayerElement: textLayer,
			annotationLayerElement: annotationLayer,
			editorLayerElement: editorLayer
		}
	}

	_removePageDOM(pageNumber) {
		var pageContainer = this.pages[pageNumber].pageContainer;
		if (!pageContainer) {
			return;
		};
		while (pageContainer.firstChild) {
			pageContainer.removeChild(pageContainer.firstChild);
		}
		this.pages[pageNumber].state = false;
	}

	_removePage(pageNumber) {
		if (!this.pages[pageNumber]) return;
		// We cancel the render task as we do not want to use ressources loading
		// content that is no longer visible
		if (this.pages[pageNumber].canvasRenderTask) {
			this.pages[pageNumber].canvasRenderTask.cancel();
		}

		delete this.pages[pageNumber].state;
		delete this.pages[pageNumber].pageDomElement;
		delete this.pages[pageNumber].canvasDomElement;
		delete this.pages[pageNumber].canvasAnnotationDomElement;
		delete this.pages[pageNumber].textDomElement;
		delete this.pages[pageNumber].editorDomElement;
		delete this.pages[pageNumber].canvasRenderTask;
	}

	_handleSinglePageAddition(pageIndex) {
		this.pdfObject.getPage(pageIndex).then(pagePDF => {

			var viewport = pagePDF.getViewport({ scale: this.scale });
			var pageContainer = this._createPageContainer(viewport, pagePDF._pageIndex);
			this.pages[pagePDF._pageIndex + 1].pageContainer = pageContainer;
			this.container.appendChild(pageContainer);

			var page = this._createPageDOM(pagePDF, this.scale);
			if (pagePDF._pageIndex + 1 == pageIndex) {
				this.container.appendChild(page.pageContainer);
			}
			this.setPageAttributes(page, pagePDF);
			this.renderPage(page, pagePDF, this.scale);
		});
	}

	_removeAllPageDOM() {
		let child;
		while (child = this.container.firstChild) {
			this.container.removeChild(child);
		}

		if (!this.keepCanvas) {
			return;
		}

		// We remove any existing renderTask as it can happen that the viewer
		// tries to render a new page while another has not finished, this will
		// result in an incorrectly drawn page. This only applies to keepCanvas.
		this.pages.forEach(page => {
			if (page.canvasRenderTask) {
				page.canvasRenderTask.cancel();
			}
		});
	}

	//start page offset handled correctly
	// page not being added when scrolling up ?
	_handleMultiplePageAddition() {
		var pageOffsetStart = this.currentPage - this.nbPagesLoaded;
		var pageOffsetEnd = this.currentPage + this.nbPagesLoaded;
		pageOffsetStart = Math.max(pageOffsetStart, 1);
		pageOffsetEnd = Math.min(pageOffsetEnd, this.pdfObject.numPages);

		for (var i = pageOffsetStart; i <= pageOffsetEnd; i++) {
			//do not render the page if state is not false
			if (this.pages[i].state) continue;
			this.pages[i].state = 'loading';
			//reference to i wrong
			this.pdfObject.getPage(i).then(pagePDF => {
				var page = this._createPageDOM(pagePDF);
				this.setPageAttributes(page, pagePDF);
				this.renderPage(page, pagePDF, this.scale);
			});
		}
	}

	// TODO: Cancel loading pages
	_handleMultiplePageRemoval() {
		var pageOffsetStart = this.currentPage - this.nbPagesLoaded;
		var pageOffsetEnd = this.currentPage + this.nbPagesLoaded;
		if (pageOffsetStart < 1) pageOffsetStart = 1;

		for (var i = 1; i < this.pdfObject.numPages; i++) {
			if (!this.pages[i].state) continue;
			if (i < pageOffsetStart || i > pageOffsetEnd) {
				this._removePageDOM(i);
				// remove reference from list
				this._removePage(i);
			}
		}
	}

	/*
	 *	Initialize the boot sequence for the PDF
	 */
	load(url) {
		// We cannot keep the canvas if there are more than 1 page being drawn
		this.setKeepCanvas(this.navigationStyle == 'swipe');
		var loadingTask = PDFJS.getDocument(url);
		loadingTask.promise.then(pdf => {
			this.pdfObject = pdf;
			this.numPages = pdf.numPages;
			this.loaded = true;
			this.eventBus = new EventBus();
			this.linkService = new LinkService(this);
			this.pdfFindController = new PDFFindController({
				linkService: this.linkService,
				eventBus: this.eventBus
			});
			this.pdfFindController.setDocument(pdf);
			this.initPages().then(() => {
				this._initializeDom();
				let eventDomIntialize = new CustomEvent('pdfreader-dom-initialized', {
					bubbles: true,
					detail: {
						nbPages: this.pdfObject.numPages,
						element: this,
						viewer: this.container
					}
				});


				if (this.pdfObject.numPages > 1) {
					this._createNavigationButtons();
				}
				this.container.dispatchEvent(eventDomIntialize);
				this.renderPageCurrentContext();
				this.sliderPage.setAttribute('data-max', this.pdfObject.numPages);
				let event = new CustomEvent('pdfreader-loaded', {
					bubbles: true,
				});
				this.container.dispatchEvent(event);
				//SET OUTLINE LEFTMENU
				this.renderPDFOutline();
			});
		});
	}

	/*
	 *	Allows to directly navigate to a specific page
	 */
	setPageFromExternal(pageIndex) {
		this.setPage(pageIndex);
	};

	setPage(pageNumber) {
		if (typeof pageNumber == 'undefined') return;
		if (pageNumber < 0) return;
		if (pageNumber > this.pdfObject.numPages) return;
		this.currentPage = parseInt(pageNumber);

		if (this.navigationStyle == 'vertical' ||
			this.navigationStyle == 'horizontal'
		) {
			this._scrollToPage(pageNumber);
		}
		this.renderPageCurrentContext();
	}

	setPreviousPage() {
		var targetPage = this.currentPage - 1;
		if (targetPage < 1) return;

		this.setPage(targetPage);
	}

	// TODO: Do not compute the scale here, just set a property "fitType", then
	// check in handleMultiplePageAddition() for the type used
	setFitPage(type) {
		if (type == 'fitWidth') {
			var width = this.pages[this.currentPage].pageView[this.VIEW_WIDTH_INDEX];
			var newZoom = window.innerWidth / width;
		} else if (type == 'fitHeight') {
			var height = this.pages[this.currentPage].pageView[this.VIEW_HEIGHT_INDEX];
			var newZoom = window.innerHeight / height;
		} else if (!isNaN(type)) {
			if (type < 0.4 || type > 4) {
				return;
			}
			var newZoom = type;

		} else {
			console.warn('fit type not supported');
			return;
		}
		this.setZoom(newZoom);
		//this.scroller.setZoomStartFromRange(false,newZoom);
	}

	setPerfectScale(pageView) {
		let width = pageView[this.VIEW_WIDTH_INDEX];
		var height = pageView[this.VIEW_HEIGHT_INDEX];
		if (width > height) {
			this.setFitPage('fitWidth');
			return;
		}
		//this.zoom = this.container.innerHeight / height;
		this.setFitPage('fitHeight');
	}

	setZoom(zoom) {
		let timeOrigin = new Date().getTime();
		if (this.zoomStart && this.zoomStart > timeOrigin) {
			//console.log(this.zoomStart,timeOrigin);
			//console.log('mustwait');
			return;
		}
		this.zoomStart = timeOrigin + 1000;
		zoom = (Math.round(zoom * 100) / 100);
		var oldZoom = parseFloat(getScale(this.container));

		let rectPlayer = this.getBoundingClientRect()
		let rectViewer = this.container.getBoundingClientRect();

		let rectViewerInitial = { width: this.pages[this.currentPage].pageView[this.VIEW_WIDTH_INDEX], height: this.pages[this.currentPage].pageView[this.VIEW_HEIGHT_INDEX] };

		if (this.spreadMode == 'double') {
			rectViewerInitial.width += this.pages[this.currentPage + 1].pageView[this.VIEW_WIDTH_INDEX];
			//rectViewerInitial.height += this.pages[this.currentPage + 1].pageView[this.VIEW_HEIGHT_INDEX];
		}

		let proposalRect = { width: (rectViewerInitial.width * zoom), height: (rectViewerInitial.height * zoom) };
		let translateX;
		if (proposalRect.width <= rectPlayer.width) {
			translateX = Math.round((rectPlayer.width - proposalRect.width) / 2 / zoom * 100) / 100;
		} else {
			translateX = 0;
		}
		let translateY;
		if (proposalRect.height <= rectPlayer.height) {
			translateY = Math.round((rectPlayer.height - proposalRect.height) / 2 / zoom * 100) / 100;
		} else {
			translateY = 0;
		}

		this.zoom = zoom;
		this.container.setAttribute('style', 'transform:scale(' + zoom + ') translate(' + translateX + 'px,' + translateY + 'px);');
		// Hack to allow the user to see the top of the page when zooming
		let event = new CustomEvent('pdfreader-zoomed', {
			bubbles: true,
			detail: {
				zoom: this.zoom
			}
		});
		this.container.dispatchEvent(event);

		this.pages.forEach(page => {

			if (page.editorToolBar) {
				page.editorToolBar.zoom = this.zoom;
				console.log(page, this.zoom);
			}
		});

		if (!isDesktop()) {
			this.zoomStart = new Date().getTime() + 100;
		} else {
			this.zoomStart = new Date().getTime() + 50;
		}

	}


	setZoomIn() {
		var newZoom = +(this.zoom + 0.1).toFixed(1);
		if (newZoom > 2) {
			return;
		}
		this.setZoom(newZoom);
		//this.scroller.setZoomStart(false,newZoom);
	}

	setZoomOut() {
		var newZoom = +(this.zoom - 0.1).toFixed(1);
		if (newZoom <= 0) {
			return;
		}
		this.setZoom(newZoom);
		//this.scroller.setZoomStart(false,newZoom);
	}

	resetPage() {
		var targetPage = this.currentPage;
		if (targetPage > this.pdfObject.numPages) return;
		this.currentPage = targetPage;
		this.renderPageCurrentContext();
	}

	setNextPage() {
		var targetPage = this.currentPage + 1;
		if (targetPage > this.pdfObject.numPages) return;
		this.setPage(targetPage);
	}

	// TODO: The margin between the pages are not computed dynamically, this can
	// lead to issues when changing the margins in the CSS...
	computeCurrentViewingPage(scrollStart) {
		var currentPage = 1;
		var step = this.spreadMode == 'single' ? 1 : 2;
		var computedScroll = (this.pages[currentPage].pageView[this._scrollDimension] * this.zoom);

		while (computedScroll < scrollStart) {
			var dimension = this.pages[currentPage].pageView[this._scrollDimension];
			computedScroll += (dimension * this.zoom);
			currentPage += step;
		}

		if (this.lastPage != this.currentPage) {
			this.lastPage = this.currentPage;
		}

		this.currentPage = currentPage;
	}

	_scrollToPage(pageIndex) {
		var pageOffset;
		var scrollDirection = this.navigationStyle == 'vertical' ? 'scrollTop' : 'scrollLeft';

		if (this.navigationStyle == 'vertical') {
			pageOffset = this.pages[pageIndex].pageContainer.offsetTop * this.zoom;
		} else if (this.navigationStyle == 'horizontal') {
			pageOffset = this.pages[pageIndex].pageContainer.offsetLeft * this.zoom;
		}

		this.scrollContainer[scrollDirection] = pageOffset;
	}

	// TODO: single page and multiple page should be handled the same way, as
	// swipe can now have more than one page with the spreadMode attribute.
	// TODO: We could keep the canvas appended in the DOM and redraw over it in
	// swipe mode
	renderPageCurrentContext() {
		switch (this.navigationStyle) {
			case 'vertical':
			case 'horizontal':
				this._handleMultiplePageAddition();
				this._handleMultiplePageRemoval();
				break;
			case 'swipe':
				if (this.spreadMode == 'single') {
					this._removeAllPageDOM();
					this._handleSinglePageAddition(this.currentPage);
				} else if (this.spreadMode == 'double') {
					this._removeAllPageDOM();
					this._handleSinglePageAddition(this.currentPage);
					this._handleSinglePageAddition(this.currentPage + 1);
				}
				break;
		}

		let event = new CustomEvent('pdfreader-page-changed', {
			bubbles: true,
			detail: {
				currentPage: this.currentPage,
			}
		});

		this.container.dispatchEvent(event);
	}

	setPageAttributes(page, pagePDF) {
		Object.assign(this.pages[pagePDF._pageIndex + 1], {
			pageDomElement: page.pageContainer,
			canvasDomElement: page.canvasElement,
			canvasAnnotationDomElement: page.canvasAnnotationElement,
			editorDomElement: page.editorLayerElement,
			textDomElement: page.textLayerElement,
			editorToolBar: false,
		});
	}

	renderPage(page, pagePDF, scale) {
		var params = {
			canvas: page.canvasElement,
			textLayer: page.textLayerElement,
			annotationLayer: page.annotationLayerElement,
			pagePDF: pagePDF,
			scale: scale,
			renderInteractiveForms: true
		};

		if (this.renderType == 'svg') {
			this.renderPDFSvg({ renderParameters: params });
		} else if (this.renderType == 'canvas') {
			this.renderPDFCanvas({ renderParameters: params });
		}

		let event = new CustomEvent('pdfreader-page-rendered', {
			bubbles: true,
			detail: {
				pageIndex: this.currentPage
			}
		});
		this.container.dispatchEvent(event);

		this.renderPDFText({ renderParameters: params });

		if (this._annotationsAllowed) {
			this.renderPDFAnnotation({ renderParameters: params });
		}
		this.setEditorToolBar(pagePDF);
		if (this.loader) this.loader.remove();
	}
	setEditorToolBar(pagePDF) {
		const page = this.pages[pagePDF._pageIndex + 1];
		if (page.editorToolBar?.floatTextEditingBox?.pickers?.container) {
			page.editToolBar.floatTextEditingBox.pickers?.container.remove();
		}
		if (page.editorToolBar?.container) {
			page.editorToolBar.container.remove();
		}
		if (page.editorToolBar) {
			page.editorToolBar.remove();
		}
		const editorToolBar = document.createElement('intkn-edit-toolbar');
		this.pages[pagePDF._pageIndex + 1].editorToolBar = editorToolBar;
		editorToolBar.element = this.data;
		editorToolBar.zoom = this.zoom;
		editorToolBar.context = {
			page: pagePDF._pageIndex + 1
		};
		editorToolBar.container = page.editorDomElement;
		page.pageContainer.appendChild(editorToolBar);
		editorToolBar.loadComponents();
	}
	renderPDFCanvas(data) {
		var params = data.renderParameters;
		var context = params.canvas.getContext('2d');
		var renderContext = {
			canvasContext: context,
			viewport: params.pagePDF.getViewport({ scale: this.resolutionFactor * params.scale })
		}
		var renderTask = params.pagePDF.render(renderContext);
		this.pages[params.pagePDF._pageIndex + 1].canvasRenderTask = renderTask;
		renderTask.promise.then(() => {
			this.pages[params.pagePDF._pageIndex + 1].state = 'rendered';
			params.canvas.style.opacity = '1';
			this.pages[params.pagePDF._pageIndex + 1].canvasAnnotationDomElement.style.opacity = '1';
		});
	}

	renderPDFSvg(data) {
		var params = data.renderParameters;
		params.pagePDF.getOperatorList()
			.then(opList => {
				var svgGfx = new PDFJS.SVGGraphics(params.pagePDF.commonObjs, params.pagePDF.objs);
				var viewport = params.pagePDF.getViewport({ scale: this.resolutionFactor * params.scale });
				return svgGfx.getSVG(opList, viewport);
			}).then(svg => {
				svg.classList.add('svgPDF');
				params.canvas.parentNode.replaceChild(svg, params.canvas);
				params.canvas = svg;
				this.pages[params.pagePDF._pageIndex + 1].state = 'rendered';
				params.canvas.style.opacity = '1';
				this.pages[params.pagePDF._pageIndex + 1].canvasAnnotationDomElement.style.opacity = '1';
			});
	}

	renderPDFText(data) {
		var params = data.renderParameters;
		//we are rendering the text in the textLayer DOMElement
		var textContent = params.pagePDF.getTextContent();
		textContent.then(textContent => {
			var layerFactory = new TextLayerBuilder({
				textLayerDiv: params.textLayer,
				pageIndex: params.pagePDF._pageIndex,
				viewport: params.pagePDF.getViewport({ scale: params.scale }),
				enhanceTextSelection: true,
				eventBus: this.eventBus
			});

			layerFactory.setTextContent(textContent);
			layerFactory.render();
		});
	}

	renderPDFAnnotation(data) {
		var params = data.renderParameters;
		//we are rendering the text in the textLayer DOMElement
		var annotationContent = params.pagePDF.getAnnotations();

		annotationContent.then(_ => {
			// TODO: Implement AnnotationLayerBuilder to handle text widgets
			var annotationFactory = new AnnotationLayerBuilder({
				pageDiv: params.annotationLayer,
				pdfPage: params.pagePDF,
				linkService: new LinkService(this),
				downloadManager: '',
				imageResourcesPath: '',
				renderInteractiveForms: true,
				l10n: NullL10n, //TODO: Specify own locale 
				pdfViewer: this
			});
			annotationFactory.render(params.pagePDF.getViewport({ scale: params.scale }));
		});
	}

	renderPDFOutline() {
		var metadata = this.pdfObject.getMetadata();
		metadata.then(function (meta) {
			// console.log(meta);
			// meta.info.IsAcroFormPresent
		});
		var outlineContent = this.pdfObject.getOutline();
		outlineContent.then(outline => {
			//we are rendering the text in the textLayer DOMElement
			this.outline = outline;
			let event = new CustomEvent('pdfreader-outline-loaded', {
				bubbles: true,
				detail: {
					outline: this.outline
				}
			});
			this.container.dispatchEvent(event);
		});
	}
	refreshPDFOutline(outlinebar) {
		var pdfOutlineViewer = new PDFOutlineViewer({
			container: outlinebar,
			linkService: new LinkService(this),
			eventBus: this.eventBus
		});
		//console.log("outline",outline);
		pdfOutlineViewer.render({ outline: this.outline });
		let event = new CustomEvent('pdfreader-outline-rendered', {
			bubbles: true,
		});
		this.container.dispatchEvent(event);
	}

	/**
	 *
	 *
	 * @param {Object} args
	 * @param {Boolean} args.caseSensitive
	 * @param {Boolean} args.findPrevious
	 * @param {Boolean} args.highlightAll
	 * @param {Boolean} args.phraseSearch
	 * @param {String} args.query
	 */
	findText(args) {
		this.pdfFindController.executeCommand('find', {
			caseSensitive: args.caseSensitive || false,
			findPrevious: args.findPrevious,
			highlightAll: args.highlightAll || true,
			phraseSearch: args.phraseSearch || true,
			query: args.query
		});
	}

	createInternalAnnotation(data) {
		let { type, id, tagName, value, checked = null } = data;
		var pageIndex = this.currentPage;
		if (!pageIndex) {
			return false;
		}
		let annotation = this.annotationController._annotations[id];

		if (annotation) {
			annotation.annotation.setType('internalAnnotation');
			this.updateInternalAnnotation({ value: value, checked: checked, annotation: annotation.annotation });
			return;
		}

		annotation = new Annotation();
		annotation.setType('internalAnnotation');
		annotation.setId(id); // e.g. R0953
		annotation.setTargetId(this.id);
		annotation.setPage(pageIndex);
		annotation.setTargetForInternalAnnotation(tagName, type, value, id, checked);
		this.annotationController.add(annotation);

		var event = new CustomEvent('pdfreader-add-note', {
			bubbles: true,
			detail: {
				...annotation,
				externalId: annotation.id
			}
		});
		this.container.dispatchEvent(event);
	}

	updateInternalAnnotation(data) {
		var checked = data.checked;
		var annotation = data.annotation;

		annotation.target.selector.value = data.value;
		if (typeof (checked) !== 'undefined') {
			annotation.target.selector.checked = checked;
		}
		let event = new CustomEvent('pdfreader-update-internal-note', {
			bubbles: true,
			detail: {
				...annotation,
				externalId: annotation.id
			}
		});
		this.container.dispatchEvent(event);
	};

	_getPageIndexSelection(selection) {
		var pagesOffsets = this._getPagesOffsets();
		for (var pageIndex = pagesOffsets.start; pageIndex <= pagesOffsets.end; pageIndex++) {
			if (!this.pages[pageIndex].textDomElement) {
				continue;
			}
			if (this.pages[pageIndex].textDomElement.contains(selection.anchorNode)) {
				return pageIndex;
			}
		}
		return false;
	}

	/**
	 *
	 *
	 * @param {Annotation} annotation entire annotation
	 * @memberof PDFViewer
	 */
	updateAnnotation(annotation) {
		this.annotationController.update(annotation);
		this.annotationController.refresh(annotation.page);
		let event = new CustomEvent('pdfreader-update-note', {
			bubbles: true,
			detail: {
				...annotation,
				externalId: annotation.id
			}
		});
		this.container.dispatchEvent(event);
	}

	loadAnnotations(annotations) {
		//console.log(annotations);
		this.annotationController.removeAllAnnotations();
		for (let object of annotations) {
			if (this.annotationController.get(object.id)) {
				//console.log('alreadyloaded',object.id);
				continue;
			}
			let annotation = new Annotation();
			annotation.loadFromObject(object);
			this.pushAnnotation(annotation);

			//console.log(annotations);

		}
	}

	pushAnnotation(annotation) {

		let event = new CustomEvent('pdfreader-display-popup', {
			bubbles: true,
			detail: {
				type: 'edit-annotation',
				element: annotation
			}
		});
		let callBack = (evt) => {
			// TODO: Inject callback, do not set it here as it differs
			// for each integration
			evt.stopPropagation();
			this.container.dispatchEvent(event);
		};
		this.annotationController.add(annotation, callBack);
	}

	/**
	 * @param {String} uuid
	 * @memberof PDFViewer
	 */
	removeAnnotation(uuid) {
		let annotation = this.annotationController.get(uuid);
		this.annotationController.remove(uuid);

		var event = new CustomEvent('pdfreader-remove-note', {
			bubbles: true,
			detail: {
				externalId: annotation.id,
				type: annotation.type
			}
		});

		this.container.dispatchEvent(event);
	}
}

customElements.define('intkn-pdfviewer', PDFViewer);

export default PDFViewer;