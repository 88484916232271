export default class SimpleLinkService {
  constructor(pdfViewer) {
    this.externalLinkTarget = 2;
    //this.externalLinkRel = "noreferrer nofollow";
    this._pagesRefCache = [];
    this._pdfViewer = pdfViewer;
    this.externalLinkEnabled = true;
  }


  /**
   * @returns {number}
   */
  get pagesCount() {
    return this._pdfViewer.numPages;
  }

  /**
   * @returns {number}
   */
  get page() {
    return 0;
  }

  /**
   * @param {number} value
   */
  set page(value) { }

  /**
   * @returns {number}
   */
  get rotation() {
    return 0;
  }

  /**
   * @param {number} value
   */
  set rotation(value) { }

  /**
   * @param dest - The PDF destination object.
   */
  navigateTo(dest) {
    //console.log(dest);

    let destRef = dest[0], pageNumber;

    if (destRef instanceof Object) {
      pageNumber = this._cachedPageNumber(destRef);
      if (pageNumber === null) {
        this._pdfViewer.pdfObject.getPageIndex(destRef).then((pageIndex) => {
          this.cachePageRef(pageIndex + 1, destRef);
          //console.log('pageIndex',pageIndex);
          this.navigateTo(dest);

        }).catch(() => {
          console.error(`PDFLinkService.navigateTo: "${destRef}" is not ` +
            `a valid page reference, for dest="${dest}".`);
        });
        return;
      }
    } else if (Number.isInteger(destRef)) {
      pageNumber = destRef + 1;
    } else {
      console.error(`PDFLinkService.navigateTo: "${destRef}" is not ` +
        `a valid destination reference, for dest="${dest}".`);
      return;
    }
    if (!pageNumber || pageNumber < 1 || pageNumber > this.pagesCount) {
      console.error(`PDFLinkService.navigateTo: "${pageNumber}" is not ` +
        `a valid page number, for dest="${dest}".`);
      return;
    }

    this._pdfViewer.setPage(pageNumber);
  }

  /**
   * @param dest - The PDF destination object.
   * @returns {string} The hyperlink to the PDF object.
   */
  getDestinationHash(dest) {
    if (typeof dest === "string") {
      return this.getAnchorUrl("#" + escape(dest));
    }

    if (Array.isArray(dest)) {
      const str = JSON.stringify(dest);
      return this.getAnchorUrl("#" + escape(str));
    }

    return this.getAnchorUrl("");
  }

  /**
   * @param hash - The PDF parameters/hash.
   * @returns {string} The hyperlink to the PDF object.
   */
  getAnchorUrl(anchor) {
    return (this.baseUrl || "") + anchor;
  }

  /**
   * @param {string} hash
   */
  setHash(hash) {
    console.log('setHash', hash);
  }

  /**
   * @param {string} action
   */
  executeNamedAction(action) {
    switch (action) {
      case "GoBack":
        if (this.pdfHistory) {
          this.pdfHistory.back();
        }

        break;

      case "GoForward":
        if (this.pdfHistory) {
          this.pdfHistory.forward();
        }

        break;

      case "NextPage":
        if (this.page < this.pagesCount) {
          this.page++;
        }

        break;

      case "PrevPage":
        if (this.page > 1) {
          this.page--;
        }

        break;

      case "LastPage":
        this.page = this.pagesCount;
        break;

      case "FirstPage":
        this.page = 1;
        break;

      default:
        break;
    }

    this.eventBus.dispatch("namedaction", {
      source: this,
      action
    });
  }

  /**
   * @param {number} pageNum - page number.
   * @param {Object} pageRef - reference to the page.
   */
  cachePageRef(pageNum, pageRef) {
    if (!pageRef) {
      return;
    }

    const refStr = pageRef.gen === 0 ? `${pageRef.num}R` : `${pageRef.num}R${pageRef.gen}`;
    this._pagesRefCache[refStr] = pageNum;
  }
  _cachedPageNumber(pageRef) {
    const refStr = pageRef.gen === 0 ? `${pageRef.num}R` : `${pageRef.num}R${pageRef.gen}`;
    return this._pagesRefCache && this._pagesRefCache[refStr] || null;
  }

  isPageVisible(pageNumber) {
    return this.pdfViewer.isPageVisible(pageNumber);
  }
}