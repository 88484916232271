import Api from './Api';
const getUrlImg = function (element, user) {
    if (typeof (element.id) == 'undefined') {
        return '/office/ressources/img/intkn_nobody.svg';
    }

    var rootQuery = "/api2/actor/download/";
    if (element.id.indexOf('@') == -1) {
        var rootQuery = "/api2/object/download/";
    }

    var width = "60";
    var height = "60";
    if (typeof (element.size) !== 'undefined'
        && typeof (element.size.width) !== 'undefined') {

        width = element.size.width;
        height = element.size.height;
    }

    var format = 'png';
    if (typeof (element.format) !== 'undefined') {
        format = element.format;
    }

    var type = "image";
    if (typeof (element.type) !== 'undefined') {
        type = element.type;
    }
    var query = {
        action: "download",
        user: user.login,
        list: [{
            id: element.id,
            options: {
                transfert: "inline",
                size: {
                    width: width,
                    height: height
                },
                typeImg: type,
                resume: "on"
            }
        }]
    };
    if (format) {
        query.list[0].options.type = format;
    }
    if (typeof (element.name) !== 'undefined') {
        query.list[0].options.image = element.name;
    }
    if (typeof (element.image) !== 'undefined') {
        query.list[0].options.image = element.image;
    }
    if (typeof (element.currentTime) != 'undefined' && element.currentTime != '') {
        query.list[0].options.currentTime = element.currentTime;
    }
    if (typeof (element.embeddedKey) !== 'undefined') {
        query.list[0].options.embeddedKey = element.embeddedKey;
    }
    if (typeof (element.metadata) != 'undefined') {
        query.list[0].options.typeImg = 'thumbnail';
    }

    return rootQuery + '?query=' + encodeURIComponent(btoa(JSON.stringify(query)))
        + '&key=' + btoa(user.key)
        + '&login=' + btoa(user.login)
        + '&encryption=true';

};
const isFile = [
    "calcul",
    "document",
    "pdf",
    "compression",
    "presentation",
    "graphicEditor",
    "vectorialEditor",
    "designEditor",
    "file"
];
const exportFromTextarea = (string) => {
    var exp1 = new RegExp("\n", "gi");
    var exp2 = new RegExp("\r", "gi");
    var exp3 = new RegExp("\t", "gi");
    return string.replace(exp3, '<br>').replace(exp2, '<br>').replace(exp1, '<br>');
};
const importToTextarea = (string) => {
    var exp1 = new RegExp("&lt;br&gt;", "gi");
    var exp2 = new RegExp("<br>", "gi");
    var exp3 = new RegExp("<br/>", "gi");
    return string.replace(exp3, "\n").replace(exp2, "\n").replace(exp1, "\n");
};
const donwloadMedia = (object, user, options, returnUrl) => {
    const query = {
        user: user.login,
        action: 'download',
        list: [
            {
                id: object.id,
                options: {
                    transfert: "attachment",
                    resume: "off",
                    //type: object.subType,
                    embeddedKey: object.embeddedKey,
                    source: true
                },
            }
        ]
    };
    const url =
        "/api2/object/download/" +
        "?signature=1&login=" +
        user.login +
        "&key=" +
        user.key +
        "&query=" +
        encodeURIComponent(JSON.stringify(query));
    if (returnUrl) return url;
    //console.log(url)
    const save = document.createElement("a");
    save.href = url;
    document.body.appendChild(save);
    save.click();
    document.body.removeChild(save);
};
const getMediaPlayer = (object, user, options, returnUrl) => {
    if (!object.embeddedKey) {
        return;
    }
    var embeddedKey = "&embeddedKey=" + object.embeddedKey;
    var scope = object.scope.split(",")[0];
    var url = "https://" + getSubDomain() + "." +
        user.domain
    switch (object.subType) {
        case 'document':
        case 'calcul':
        case 'presentation':
            url += "/officeplayer/?";
            url += "&lang=" + user.language + "&scope=" + scope + embeddedKey;
            if (object.export == '1') {
                url += '&action=getLibrary';
            }
            if (options && options.edit) {
                url += "&token=" + btoa(object.id + "--writer");
            } else {
                url += "&token=" + btoa(object.id + "--reader");
            }
            break;
        default:
            url += "/mediaplayer/?token=";
            var jsonToken = {
                lang: user.language,
                scope: object.scope.split(",")[0],
                embeddedKey: object.embeddedKey,
                id: object.id
            };
            if (object.export == '1') {
                jsonToken.action = 'getLibrary';
            }
            if (options && options.edit) {
                jsonToken.edition = "writer";
            } else {
                jsonToken.edition = "reader";
            }
            if (options && options.comments) {
                jsonToken.comments = options.comments;
            } else {
                jsonToken.comments = false;
            }
            if (options && options.openWindow) {
                jsonToken.openWindow = options.openWindow;
            } else {
                jsonToken.openWindow = false;
            }
            url += btoa(JSON.stringify(jsonToken));
            //activity.setActionByType(false, object, false, true);
            break;
    }

    if (returnUrl) {
        return url;
    }
    window.open(url);
}
const getSubDomain = function () {
    var url = window.location.href;
    var url_parts = url.split('/');
    var subdomains_parts = url_parts[2].split(':');
    var subdomains = subdomains_parts[0].split('.');
    var nbSubDomains = subdomains.length;

    return subdomains[0];
};
const getDomain = function () {
    var url = window.location.href;
    var url_parts = url.split('/');
    var subdomains_parts = url_parts[2].split(':');
    var subdomains = subdomains_parts[0].split('.');
    var nbSubDomains = subdomains.length;
    var domain = subdomains[nbSubDomains - 2] + '.' + subdomains[nbSubDomains - 1];
    return domain;
};
const getPathName = function () {
    var url = window.location.pathname;
    var urlParts = url.split('/');
    //console.log(urlParts);
    var path = [];
    var nbPath = urlParts.length;
    for (var u = 0; u < nbPath; u++) {
        if (urlParts[u] !== '' && urlParts[u] !== 'office') {
            path.push(urlParts[u]);
        }
    }
    return path;
};
const getUserPreferences = function () {
    let get = getValuesUrl();
    let lang = 'fr';
    if (getCookie('lang')
        && !get.values.language) {
        lang = getCookie('lang');
    }
    var job = '-';
    if (getCookie('job')) {
        job = atob(getCookie('job'));
    }
    var bitrate = '720p';
    if (getCookie('bitrate')) {
        bitrate = getCookie('bitrate');
    }
    var organization = '-';
    if (getCookie('organization')) {
        organization = atob(getCookie('organization'));
    }
    let login = 'nobody';
    if (getCookie('login')) {
        login = getCookie('login');
    }
    let key = 'e3561504b2764ed07dcda513a9a7f3e0';
    if (getCookie('tokenSession')) {
        key = getCookie('tokenSession')
    }
    let publicKey = '';
    if (getCookie('tokenPublic')) {
        let publicKey = getCookie('tokenPublic');
    }
    let firstName = 'nobody';
    if (getCookie('firstName')) {
        firstName = atob(getCookie('firstName'));
    }
    let name = 'nobody';
    if (getCookie('name')) {
        name = atob(getCookie('name'));
    }
    let sessionId = false;
    if (getCookie('sessionId')) {
        sessionId = getCookie('sessionId');
    }
    let level = 'user';
    if (getCookie('level')) {
        level = getCookie('level');
    }
    return {
        login: login,
        key: key,
        publicKey: publicKey,
        language: lang,
        firstName: firstName,
        organization: organization,
        job: job,
        bitrate: bitrate,
        sessionId: sessionId,
        name: name,
        level: level,
        domain: getDomain()
    };
}
const setCookie = function (name, value, expires, path, domain, secure) {
    document.cookie = name + "=" + escape(value) +
        ((expires) ? "; expires=" + expires.toGMTString() : "") +
        ((path) ? "; path=" + path : "; path=/") +
        ((domain) ? "; domain=" + domain : "; domain=" + getDomain()) +
        ((secure) ? "; secure" : "");
};
const getCookie = function (name) {
    if (document.cookie.length == 0) {
        return false;
    }
    let cStart = document.cookie.indexOf(name + "=");
    if (cStart == -1) {
        return false;
    }
    cStart = cStart + name.length + 1;
    let cEnd = document.cookie.indexOf(";", cStart);
    if (cEnd == -1) {
        cEnd = document.cookie.length;
    }
    return unescape(document.cookie.substring(cStart, cEnd));
};
const removeCookie = function (label) {
    let dt = new Date("December 25, 1995 23:15:20");
    setCookie(label, '', dt);
};
const getValuesUrl = function () {
    if (window.location.toString().indexOf('?') === -1) {
        return {
            values: {},
            string: ''
        };
    }
    let values = {};
    let query = window.location
        .toString()
        // get the query string
        .replace(/^.*?\?/, '')
        .replace(/#.*$/, '')
        .split('&');
    for (var i = 0, l = query.length; i < l; i++) {
        let aux = decodeURIComponent(query[i]).split('=');
        values[aux[0]] = aux[1];
    }
    let string = window.location.toString().split('?');
    if (string[1].indexOf('#') !== -1) {
        let subString = string[1].split('#');
        string[1] = subString[0];
    }
    let stringValues = '?' + string[1];
    return {
        values: values,
        string: stringValues
    };
};
const getMonthLabel = function (month) {
    switch (month) {
        case 0:
            return 'january';
        case 1:
            return 'february';
        case 2:
            return 'march';
        case 3:
            return 'april';
        case 4:
            return 'may';
        case 5:
            return 'june';
        case 6:
            return 'july';
        case 7:
            return 'august';
        case 8:
            return 'september';
        case 9:
            return 'october';
        case 10:
            return 'november';
        case 11:
            return 'december';
    }
}
const getDayLabel = function (day) {
    switch (day) {
        case 0:
            return 'sunday';
        case 1:
            return 'monday';
        case 2:
            return 'tuesday';
        case 3:
            return 'wednesday';
        case 4:
            return 'thursday';
        case 5:
            return 'friday';
        case 6:
            return 'saturday';
    }
}
const notificationBubble = function (component, text, time) {
    let user = getUserPreferences();
    if (!time) {
        time = 2500;
    }

    /*if (getString) {
        text = getString(text, user.language)
    }*/

    var event = new CustomEvent('open-snackbar', {
        bubbles: true,
        detail: {
            closeTimeout: time,
            text: text
        }
    });
    component.dispatchEvent(event);
}
const sortDateStartUp = function (a, b) {
    if (parseInt(a.dateStart) < parseInt(b.dateStart)) {
        return -1;
    } else {
        return 1;
    }
};
const sortTagUp = function (a, b) {
    if (a.sortTag === false || b.sortTag === false) {
        return 1;
    }
    if (a.sortTag < b.sortTag) {
        return -1;
    } else {
        return 1;
    }
};
const sortTagDown = function (a, b) {
    if (a.sortTag === false || b.sortTag === false) {
        return 1;
    }
    if (a.sortTag > b.sortTag) {
        return -1;
    } else {
        return 1;
    }
};
const sortNameUp = function (a, b) {
    if (!a.name || !b.name) {
        return 1;
    }
    if (!a.name.toLowerCase) {
        if (a.name.toString) {
            a.name = a.name.toString();
        }
    }
    if (!b.name.toLowerCase) {
        if (b.name.toString) {
            b.name = b.name.toString();
        }
    }
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
    } else {
        return 1;
    }
};
const sortNameDown = function (a, b) {
    if (!a.name || !b.name) {
        return 1;
    }
    if (!a.name.toLowerCase) {
        if (a.name.toString) {
            a.name = a.name.toString();
        }
    }
    if (!b.name.toLowerCase) {
        if (b.name.toString) {
            b.name = b.name.toString();
        }
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return -1;
    } else {
        return 1;
    }
};
const linkify = function (str) {
    let options = {/* … */ };
    return linkifyHtml(str.toString(), options);
};
const addTimecodeAction = function (str) {
    let regex = /\b(?:(?:([0-5]\d):)?([0-5]?\d):)?([0-5]?\d)\b/gi;
    /// /^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d)$/gi;
    return str.replace(regex, (match) => {
        //console.log(match);
        let href = '<a href=javascript:void(0); class="timecodeAction" onclick="dispatchTimeToPlayer(event)">' + match.trim() + '</a>';
        return href;
    });
};
const displayDate = function (timeIn, translator) {
    let d = new Date();
    let time = parseInt(timeIn) * 1000;
    d.setTime(time);
    let today = new Date();
    let day = today.getDate();
    let month = (today.getMonth()) + 1;
    let year = today.getFullYear();
    let week = today.getDay();

    let minNormalize = function (min) {
        if (parseInt(min) < 10) {
            return ('0' + min);
        }
        return min;
    };

    let returnMonth = function (month, translator) {
        switch (month) {
            case 1:
                return translator.get('january');
            case 2:
                return translator.get('february');
            case 3:
                return translator.get('march');
            case 4:
                return translator.get('april');
            case 5:
                return translator.get('may');
            case 6:
                return translator.get('june');
            case 7:
                return translator.get('july');
            case 8:
                return translator.get('august');
            case 9:
                return translator.get('september');
            case 10:
                return translator.get('october');
            case 11:
                return translator.get('november');
            case 12:
                return translator.get('december');
        }
    };

    if (d.getDate() === day
        && (d.getMonth() + 1) === month
        && d.getFullYear() === year) {
        return (d.getHours() + 'h' + minNormalize(d.getMinutes()));
    }
    if ((d.getMonth() + 1) === month
        && d.getFullYear() === year) {
        return (d.getDate() + ' ' + returnMonth(parseInt(d.getMonth() + 1), translator));
    }
    return (d.getDate() + '/' + parseInt(d.getMonth() + 1) + '/' + d.getFullYear());
};
const setUrlImgByType = function (actor, user) {
    if (typeof (actor.id) == 'undefined') {
        return '/office/ressources/img/intkn_nobody.svg';
    }
    let rootQuery = "/api2/actor/download/";
    if (actor.id.indexOf('@') == -1) {
        rootQuery = "/api2/object/download/";
        //rootQuery = getRootExtraImg(rootQuery);
    } else {
        //rootQuery = getRootUsersImg(rootQuery);
    }
    let width = "60";
    let height = "60";
    if (typeof (actor.size) !== 'undefined'
        && typeof (actor.size.width) !== 'undefined') {

        width = actor.size.width;
        height = actor.size.height;
    }
    let typeExport = 'png';
    if (typeof (actor.typeExport) !== 'undefined') {
        typeExport = actor.typeExport;
    }
    let typeImg = "image";
    if (typeof (actor.typeImg) !== 'undefined') {
        typeImg = actor.typeImg;
    }
    let query = {
        action: "download",
        user: user.login,
        list: [{
            id: actor.id,
            options: {
                transfert: "inline",
                size: {
                    width: width,
                    height: height
                },
                typeImg: typeImg,
                resume: "on"
            }
        }]
    };
    if (typeExport) {
        query.list[0].options.type = typeExport;
    }
    if (typeof (actor.name) !== 'undefined') {
        query.list[0].options.image = actor.name;
    }
    if (typeof (actor.image) !== 'undefined') {
        query.list[0].options.image = actor.image;
    }
    if (typeof (actor.currentTime) != 'undefined' && actor.currentTime != '') {
        query.list[0].options.currentTime = actor.currentTime;
    }
    if (typeof (actor.embeddedKey) !== 'undefined') {
        query.list[0].options.embeddedKey = actor.embeddedKey;
    }
    if (typeof (actor.metadata) != 'undefined') {
        query.list[0].options.typeImg = 'thumbnail';
    }
    return rootQuery + '?query=' + encodeURIComponent(JSON.stringify(query));
};
const closeCurrentPopup = function (popup) {
    popup._close();
}
const removeCurrentPopup = function (popup) {
    popup.remove();
}
const createPopupLogin = function (src) {
    let value;
    if (src.element) {
        value = src.element;
    }
    let event = new CustomEvent('open-login-popup', {
        bubbles: true,
        detail: {
            element: value
        }
    });
    src.dispatchEvent(event);
    /*let dataId = new Date().getTime();
    let popupLogin = document.createElement('intkn-popup');
    document.body.appendChild(popupLogin);
    popupLogin.setAttribute('data-id', dataId);
    popupLogin.setAttribute('display-title','tologin');
    popupLogin.formStructure = loginFormStructure;*/
}
const createPopupSignup = function (src) {
    let value;
    if (src.element) {
        value = src.element;
    }
    let event = new CustomEvent('open-signup-popup', {
        bubbles: true,
        detail: {
            element: value
        }
    });
    src.dispatchEvent(event);
    /*let dataId = new Date().getTime();
    let popupLogin = document.createElement('intkn-popup');
    document.body.appendChild(popupLogin);
    popupLogin.setAttribute('data-id', dataId);
    popupLogin.setAttribute('display-title','tologin');
    popupLogin.formStructure = signupFormStructure;*/
}
const ucFirst = function (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}
const uniqueId = function () {
    var d = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c == 'x' ? r : (r & 0x7 | 0x8)).toString(16);
    });
    return uuid;
};
const debounce = function (func, timeout = 300) {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
}
const filterObject = function (obj, fn) {
    return Object.fromEntries(Object.entries(obj).filter(fn))
}
//MUST UPDATE AS GROUND.JS internal and inknplayer!!!!!!
const getNewSessionCourseSet = function () {
    return {
        position: {
            index: {
                current: 0,
                finished: 0,
                fraction: "0 / 1",
                inProgress: 0,
                inProgressPurcent: 0,
                nbModules: 1,
                notStarted: 0,
                purcent: 0,
            },
        },
        progress: {
            courses: {}
        },
        time: 0,
        lastAccess: Math.round(new Date().getTime() / 1000),
        dateStart: 0,
        dateEnd: 0
    };
};
const getNewSessionCourse = function (dateStart, dateEnd) {
    return {
        id: uniqueId(),
        position: {
            index: {
                current: 0,
                finished: 0,
                fraction: "0 / 1",
                inProgress: 0,
                inProgressPurcent: 0,
                nbModules: 1,
                notStarted: 0,
                purcent: 0,
            }
        },
        progress: {
            modules: {}
        },
        time: 0,
        lastAccess: Math.round(new Date().getTime() / 1000),
        dateStart: dateStart,
        dateEnd: dateEnd
    };
};

const getNewSlide = function (index = 0, id = undefined) {
    return {
        time: 0,
        reading: 1,
        lastReading: Math.round(new Date().getTime() / 1000),
        dateStart: Math.round(new Date().getTime() / 1000),
        dateEnd: 0,
        end: false,
        attempt: [],
        validated: 0,
        index: index,
        id: id,
        purcent: 0
    }
};
const Streamer = {};
const addToStreamer = function (object) {
    Streamer[object.id] = object;
}
const computeProgressCourset = function (rootObject, userObject) {
    console.log('computeProgressCourset');
    if (Streamer[rootObject.id]) {
        console.log('alreadyinstreamer');
        rootObject = Streamer[rootObject.id];
    }

    if (!rootObject.child) {
        console.log('getcourseset');
        let api = new Api(
            '/api3/object/get/',
            {
                login: userObject.login,
                key: userObject.key,
                query: {
                    action: "getLibrary",
                    user: userObject.login,
                    domain: [userObject.domain],
                    list: [{ id: rootObject.id }],
                    scope: ["courses", "library", "spaces", "groups"],
                    projection: ["nbChild", { name: "child", options: { session: true } }, "session", "creatorInfos"],
                    limit: { "start": 0, "end": 1 },
                }
            },
            {
                done: {
                    action: (self, data) => {
                        if (!data
                            || !data.dataSet
                            || !data.dataSet.query) {
                            return;
                        }
                        addToStreamer(data.dataSet.query[0]);
                        var courseSet = Streamer[data.dataSet.query[0].id];
                        self(courseSet, userObject);
                    }
                },
                empty: false,
                error: {
                    action: (evt) => console.log('errorresumecoursetgethild', evt)
                },
                progress: false
            }, computeProgressCourset
        );
        api.sendRequest();
        return;
    }

    let notStarted = 0;
    let inProgress = 0;
    let totalCourses = 0;
    let position = 0;
    let done = 0;
    let sessionScore;
    let time = 0;
    let totalPurcent = 0;
    var dateStart = Math.round(new Date().getTime() / 1000);
    var dateEnd = rootObject.sessionDuration ? Math.round(
        (new Date().getTime() + parseInt(rootObject.sessionDuration) * 1000) / 1000) : 0;

    if (rootObject.session?.tokenValidate
        || rootObject.session?.dateValidate) {
        console.log('sessionfinished');
        /*delete rootObject.session.tokenValidate;
        delete rootObject.session.dateValidate;*/
        return rootObject.session;
    }

    let sessionsData = getNewSessionCourseSet();

    let oldPurcent;
    let oldFraction;
    let oldPurcentScore;
    let oldTotalPurcentScore;

    if (rootObject.session) {
        oldPurcent = rootObject.session.position.index.purcent;
        oldFraction = rootObject.session.position.index.fraction;
        oldPurcentScore = rootObject.session.score?.purcent;
        oldTotalPurcentScore = rootObject.session.score?.totalPurcent;
    }
    if (rootObject.session?.id) {
        sessionsData.id = rootObject.session.id;
        sessionsData.dateStart = rootObject.session.dateStart;
        sessionsData.dateEnd = rootObject.session.dateEnd;
    } else {
        sessionsData.dateStart = dateStart;
        sessionsData.dateEnd = dateEnd;
    }
    console.log(oldPurcent);
    let courses = rootObject.child;
    if (courses && courses.length > 0) {
        for (let c = 0; c < courses.length; c++) {
            let course = courses[c];
            if (!course) {
                console.log('nochildcourseset');
                continue;
            }
            if (course.visibility === 'hide') {
                continue;
            }
            totalCourses++;
            const dateStartC = Math.round(new Date().getTime() / 1000);
            const dateEndC = course.sessionDuration ? Math.round((new Date().getTime() + (parseInt(course.sessionDuration) * 1000)) / 1000) : 0;
            sessionsData.progress.courses[course.id] = course.session ? course.session : getNewSessionCourse(dateStartC, dateEndC);
            time += sessionsData.progress.courses[course.id].time;
            sessionsData.time = time;
            //(sessionsData.progress.courses[course.id].position.index.finished > 0
            //	&& sessionsData.progress.courses[course.id].position.index.finished == sessionsData.progress.courses[course.id].position.index.nbModules
            //	&& (course.scoreLimit == 0 || course.scoreLimit <= sessionsData.progress.courses[course.id].score?.purcent))
            //	|| 

            if (course.scoreLimit) {
                if (!sessionScore) {
                    sessionScore = {
                        purcent: 0,
                        totalPurcent: 0,
                        nbScored: 0,
                        details: {},
                        nbModule: 1
                    }
                } else {
                    sessionScore.nbModule++;
                }
            }
            if (sessionsData.progress.courses[course.id].score) {
                let score = sessionsData.progress.courses[course.id].score;
                if (!sessionScore) {
                    sessionScore = {
                        purcent: 0,
                        totalPurcent: 0,
                        nbScored: 0,
                        details: {},
                        nbModule: 0
                    }
                }
                sessionScore.totalPurcent += parseFloat(score.purcent) ?? 0;
                sessionScore.nbScored++;
                sessionScore.details[course.id] = score;
            }
            if (sessionsData.progress.courses[course.id].tokenValidate
                || sessionsData.progress.courses[course.id].dateValidate) {
                position++;
                done++;
                totalPurcent += 100;
                sessionsData.last = {
                    course: {
                        id: course.id,
                        name: course.name,
                    },
                    progress: sessionsData.progress.courses[course.id].position.index.purcent
                };
                sessionsData.position.index.finished = done;
                continue;
            }
            if (sessionsData.progress.courses[course.id].position.index.inProgress > 0
                || sessionsData.progress.courses[course.id].position.index.finished > 0) {
                //position++;
                inProgress++;
                totalPurcent += sessionsData.progress.courses[course.id].position.index.purcent;
                sessionsData.last = {
                    course: {
                        id: course.id,
                        name: course.name,
                    },
                    progress: sessionsData.progress.courses[course.id].position.index.purcent
                };
                sessionsData.position.index.inProgress = inProgress;
                sessionsData.position.index.inProgressPurcent = sessionsData.progress.courses[course.id].position.index.purcent;
                continue;
            }
            notStarted++;
            sessionsData.position.index.notStarted = notStarted;
        }
    }

    if (sessionScore) {
        sessionScore.purcent = sessionScore.totalPurcent / sessionScore.nbScored;
        sessionsData.score = sessionScore;
    } else if (sessionsData.score) {
        delete sessionsData.score;
    }

    if (rootObject.session?.progress?.modules) {
        let modules = rootObject.session?.progress?.modules;
        for (var m in modules) {
            if (modules[m.lastAccess]
                && access < parseInt(modules[m.lastAccess])) {
                access = parseInt(modules[m.lastAccess]);
            }
        }
        if (access) {
            rootObject.session.lastAccess = access;
        }
    }
    sessionsData.lastAccess = Math.round(new Date().getTime() / 1000);
    sessionsData.position.index.nbModules = totalCourses ?? 0;
    sessionsData.position.index.purcent = totalCourses ? Math.round(totalPurcent / totalCourses) : 0;
    sessionsData.position.index.fraction = position + '/' + totalCourses;
    sessionsData.position.index.current = position;
    if (totalCourses
        && sessionsData.position.index.finished == totalCourses) {
        sessionsData.tokenValidate = uniqueId();
        sessionsData.dateValidate = Math.round(new Date().getTime() / 1000);
    }
    const mustbesend = (sessionsData.position.index.purcent != oldPurcent
        || sessionsData.position.index.fraction != oldFraction
        || sessionsData.score?.purcent != oldPurcentScore
        || sessionsData.score?.totalPurcent != oldTotalPurcentScore
        || sessionsData.tokenValidate);

    console.log(oldPurcent, sessionsData.position.index.purcent);

    if (!mustbesend) {
        console.log('alreadysend')
    }
    if (mustbesend) {
        console.log('directsendsessioncourseset');
        rootObject.session = sessionsData;
        sendProgressCourseSet(rootObject, userObject);
    }
    return sessionsData;
}
const sendProgressCourseSet = function (rootObject, userObject) {
    console.log('sendProgressCourseSet');
    let api = new Api(
        '/api3/object/update/',
        {
            login: userObject.login,
            key: userObject.key,
            query: {
                action: "update",
                user: userObject.login,
                domain: [userObject.domain],
                dataAction: [{
                    id: rootObject.id,
                    type: rootObject.subType,
                    name: rootObject.name,
                    embeddedKey: rootObject.embeddedKey,
                    users: [{
                        id: userObject.login,
                        setAction: rootObject.session.tokenValidate ? {
                            endOfSession: rootObject.session
                        } : {
                            session: rootObject.session
                        }
                    }]
                }]
            }
        },
        {
            done: {
                action: (self, data) => {
                    if (!data
                        || !data.dataSet
                        || !data.dataSet.query) {
                        return;
                    }
                    if (rootObject.session.tokenValidate
                        && rootObject.typeEnd == 'attestation') {
                        sendEndOfCourseSet(rootObject, 'user', userObject);
                    }
                    console.log('sessioncoursesetsaved');
                }
            },
            empty: false,
            error: {
                action: (evt) => console.log('errorsessioncoursesetsave', evt)
            },
            progress: false
        }, false
    );
    api.sendRequest();
};
function sendEndOfCourseSet(courseSet, type, userObject) {
    console.log('sendEndOfCourseSet', 'scormplayer');
    let toAdmin;
    if (type == 'admin') {
        const idAdmin = courseSet.idAdmin ? courseSet.idAdmin : courseSet.idCreator;
        //SEND MAIL END COURSE
        toAdmin = [{ id: 'contact@workstreams.ch' }, { id: idAdmin }];
        if (userObject.domain === 'e-cffe.ch') {
            toAdmin.push({ id: 'ecffe.attestation@workstreams.ch' });
        }
    }
    const url = getAttestationUrl('postSession', courseSet, userObject);

    let api = new Api(
        '/api2/mail/put/',
        {
            login: userObject.login,
            key: userObject.key,
            query: {
                action: "sendMail",
                user: userObject.login.addSlashes(),
                replyTo: 'contact@workstreams.ch',
                dataAction: [{
                    id: courseSet.id,
                    type: 'customMail',
                    rootObject: courseSet.id,
                    replaceData: {
                        nameUser: userObject.firstName + ' ' + userObject.name,
                        emailUser: userObject.login,
                        nameCourse: courseSet.name,
                        organizationUser: userObject.organization,
                        functionUser: userObject.job,
                        tokenValidate: courseSet.session.tokenValidate
                    },
                    email: toAdmin ? toAdmin : [{
                        id: userObject.login
                    }],
                    message: type == 'user' ?
                        stream.translator.getString('endOfCourseMailUser').replace(/{{attestationUrl}}/g, url)
                        : stream.translator.getString('endOfCourseMailAdmin').replace(/{{attestationUrl}}/g, url),
                    title: type == 'user' ?
                        stream.translator.getString('endOfCourseTitleMailUser')
                        : stream.translator.getString('endOfCourseTitleMailAdmin')
                }],
                domain: [
                    userObject.domain
                ]
            }
        },
        {
            done: {
                action: (self, data) => {
                    if (!data
                        || !data.dataSet
                        || !data.dataSet.query) {
                        return;
                    }
                    console.log('sendEndOfCourseDone');
                }
            },
            empty: false,
            error: {
                action: (evt) => console.log('errorsessioncoursesave', evt)
            },
            progress: false
        }, false
    );
    api.sendRequest();

    if (type == 'user') {
        sendEndOfCourseSet(courseSet, 'admin', userObject);
    }
}
const getAttestationUrl = function (subType, course, userObject) {
    var url = location.origin + '/api2/object/download/?signature=1&login=' + btoa(userObject.login) + '&key=' + btoa(userObject.key) + '&encryption=true&query=';

    var query = {
        action: "getAttestation",
        user: userObject.login,
        type: subType,
        list: [{
            id: course.id
        }],
        sessionId: course.session.id,
        transfert: "inline",
        resume: "off",
        lang: userObject.language,
        company: userObject.organization.replace(/’/gi, "'")
    };
    url += encodeURIComponent(btoa(JSON.stringify(query)));
    return url;
}
const computeProgressCourse = function (rootObject, userObject, sendForce) {
    console.log('computeProgressCourse', 'scormplayer');
    if (Streamer[rootObject.id]) {
        console.log('alreadyinstreamer');
        rootObject = Streamer[rootObject.id];
    }
    if (!rootObject.child) {

        let api = new Api(
            '/api3/object/get/',
            {
                login: userObject.login,
                key: userObject.key,
                query: {
                    action: "getLibrary",
                    user: userObject.login,
                    domain: [userObject.domain],
                    list: [{ id: rootObject.id }],
                    rootKey: rootObject.id,
                    session: true,
                    scope: ['courses', 'trajectory', 'spaces', 'groups', 'topteam', 'topmanager'],
                    projection: ["softShared", "creatorInfos", "session", "tags", "child", "parent", "path"],
                    limit: { "start": 0, "end": 1 },
                }
            },
            {
                done: {
                    action: (self, data) => {
                        if (!data
                            || !data.dataSet
                            || !data.dataSet.query) {
                            return;
                        }
                        addToStreamer(data.dataSet.query[0]);
                        var course = Streamer[data.dataSet.query[0].id];
                        course.session = rootObject.session ?? course.session;
                        course.session = self(course, userObject, sendForce);
                    }
                },
                empty: false,
                error: {
                    action: (evt) => console.log('errorresumecoursegetchild', evt)
                },
                progress: false
            }, computeProgressCourse
        );
        api.sendRequest();
        return;
    }

    if (!rootObject.session?.progress) {
        console.log('noprogressorcomputeProgressCourse');
        return rootObject.session;
    }

    if (rootObject.session?.tokenValidate
        || rootObject.session?.dateValidate) {
        console.log('coursesessionfinished');
        return rootObject.session;
    }

    let position = 0;
    let positionMax = 0;
    let score = 0;
    let nbModuleScore = 0;
    let nbModuleScored = 0;
    let modulesLoaded = false;
    let finished = 0;
    let scoreFinished = 0;
    let inprogress = 0;
    let inProgressPurcent = 0;
    let listScored = [];
    let childs = rootObject.child;
    let nbModule = rootObject.child.length;
    let session = rootObject.session;
    const listOfSequence = [
        'bloc',
        'questionnaire',
        'articulate',
        'test',
        'module'
    ];
    const listOfVisibility = [
        'invisible',
        'hide'
    ];

    let oldPurcent = rootObject.session.position.index.purcent;
    let oldFraction = rootObject.session.position.index.fraction;
    let oldPurcentScore = rootObject.session.score?.purcent;
    let oldTotalPurcentScore = rootObject.session.score?.totalPurcent;
    let oldTotalPurcent = 0;

    console.log(oldPurcent)

    for (let m = 0; m < nbModule; m++) {
        if (listOfSequence.indexOf(childs[m].subType) === -1
            || listOfVisibility.indexOf(childs[m].visibility) !== -1) {
            continue;
        }
        let currentModuleFinished = false;
        modulesLoaded = true;
        positionMax++;
        if (!session.progress?.modules?.[childs[m].id]) {
            continue;
        }

        if (childs[m].scoreLimit
            || session.progress.modules[childs[m].id].score?.index?.limit) {
            nbModuleScore++;
        }
        if (session.progress.modules[childs[m].id].score?.index?.purcent) {
            var moduleScore = parseFloat(
                session.progress.modules[childs[m].id].score.index.purcent
            );

            var detailModuleScore = {
                id: childs[m].id,
                name: childs[m].name,
                scoreLimit: childs[m].scoreLimit ?
                    childs[m].scoreLimit : session.progress.modules[childs[m].id].score?.index?.limit
                        ? session.progress.modules[childs[m].id].score?.index?.limit : 0,
                score: moduleScore,
                //MAX SCORE ???
            };
            if (childs[m].scoreLimit) {
                score += moduleScore;
                nbModuleScored++;
                if (childs[m].scoreLimit
                    <= parseFloat(
                        session.progress.modules[childs[m].id].score.index.purcent
                    )) {
                    scoreFinished++;
                    currentModuleFinished = true;
                    detailModuleScore.validated = true;
                }
            }
            listScored.push(detailModuleScore);
        }
        if (session.progress.modules[childs[m].id].position.index.purcent == 100
            || currentModuleFinished) {
            position++;
            if (!childs[m].scoreLimit || currentModuleFinished
            ) {
                finished++;
                oldTotalPurcent += 100;
            } else {
                inprogress++;
                inProgressPurcent += parseFloat(
                    session.progress.modules[childs[m].id].position.index.purcent
                );
                oldTotalPurcent += parseFloat(
                    session.progress.modules[childs[m].id].position.index.purcent
                );
            }
        } else if (session.progress.modules[childs[m].id].position.index.purcent > 0
            || session.progress.modules[childs[m].id].time) {
            inprogress++;
            inProgressPurcent += parseFloat(
                session.progress.modules[childs[m].id].position.index.purcent
            ) ?? 0;
            oldTotalPurcent += parseFloat(
                session.progress.modules[childs[m].id].position.index.purcent
            ) ?? 0;
        }
    }

    if (!modulesLoaded) {
        //CAN NOT COMPUT WITH CURRENT VALUES
        console.log('nothingforcompute')
        return rootObject.session;
    }

    //_this.getDataObject(rootObject).session.position.index.purcent = Math.round(position / positionMax * 100);
    session.position.index.purcent = oldTotalPurcent ? Math.round(oldTotalPurcent / positionMax) : 0;
    session.position.index.current = position;
    session.position.index.nbModules = positionMax;
    session.position.index.fraction = position + ' / ' + positionMax;
    session.position.index.finished = finished;
    session.position.index.inProgress = inprogress;
    session.position.index.inProgressPurcent = inProgressPurcent;
    session.position.index.notStarted = positionMax - inprogress - finished;
    let purcentScore = 0;
    if (nbModuleScore > 0) {
        purcentScore = Math.round(score / nbModuleScore);
        session.score = {
            purcent: purcentScore,
            totalPurcent: score,
            nbScored: nbModuleScored,
            nbModule: nbModuleScore,
            finished: scoreFinished,
            details: listScored
        };
    }

    if (session.position?.index?.nbModules
        && session.position.index.finished == session.position.index.nbModules) {
        session.tokenValidate = uniqueId();
        session.dateValidate = parseInt(new Date().getTime() / 1000);
    }

    const mustbesend = (session.position.index.purcent != oldPurcent
        || session.position.index.fraction != oldFraction
        || session.score?.purcent != oldPurcentScore
        || session.score?.totalPurcent != oldTotalPurcentScore
        || session.tokenValidate);
    console.log(oldPurcent, session.position.index.purcent)
    if (!mustbesend) {
        console.log('alreadysend')
    }
    if (mustbesend || sendForce) {
        console.log('DIRECT', 'directsendsessioncourse');
        rootObject.session = session;
        sendProgressCourse(rootObject, userObject);
    }
    return session;
};
const sendProgressCourse = function (rootObject, userObject) {
    console.log('sendProgressCourse', 'scormplayer');
    let api = new Api(
        '/api3/object/update/',
        {
            login: userObject.login,
            key: userObject.key,
            query: {
                action: "update",
                user: userObject.login,
                domain: [userObject.domain],
                dataAction: [{
                    id: rootObject.id,
                    type: rootObject.subType,
                    name: rootObject.name,
                    embeddedKey: rootObject.embeddedKey,
                    users: [{
                        id: userObject.login,
                        setAction: rootObject.session.tokenValidate ? {
                            endOfSession: rootObject.session
                        } : {
                            session: rootObject.session
                        }
                    }]
                }]
            }
        },
        {
            done: {
                action: (self, data) => {
                    if (!data
                        || !data.dataSet
                        || !data.dataSet.query) {
                        return;
                    }
                    if (rootObject.session.tokenValidate
                        && rootObject.typeEnd == 'attestation') {
                        sendEndOfCourseSet(rootObject, 'user', userObject);
                    }
                    console.log('AFTER', 'mustupdatecourseset');
                    if (rootObject.parent
                        && rootObject.parent?.[0]?.extension.toLowerCase().indexOf('courseset') !== -1) {
                        var courseset = Streamer[rootObject.parent[0].id];
                        if (courseset) {
                            console.log('updatechildcourseset');
                            courseset.child.filter((child) => child.id === rootObject.id)
                                .map((child) => child.session = rootObject.session);
                        }
                        computeProgressCourset(rootObject.parent[0], userObject);
                    }
                    console.log('sessioncoursesaved');
                }
            },
            empty: false,
            error: {
                action: (evt) => console.log('errorsessioncoursesave', evt)
            },
            progress: false
        }, false
    );
    api.sendRequest();
};
const computeProgressModule = function (rootObject, parentObject, forceEnd) {
    console.log('computeprogressmodule');
    if (!parentObject) {
        console.log('noparentforcomputeprogressmodule');
        return;
    }
    if (!rootObject) {
        console.log('norootobjectforcomputeprogressmodule');
        return;
    }
    if (!rootObject.session) {
        console.log('nosessionforcomputeprogressmodule');
        return;
    }
    let session = rootObject.session;
    if (!session?.progress?.modules?.[parentObject.id]) {
        return {
            progress: {
                purcent: 0,
                label: '0% ' + stream.translator.getString('accomplished'),
                explorationRate: 0
            },
            session: session
        };
    }
    if (forceEnd) {
        session.progress.modules[parentObject.id].progressEnd = true;
    }

    let position = 0;
    let nbSlides = 0;
    let explorationRate = 0;
    let purcentProgress = 0;
    if (parentObject.child?.[0]) {
        //CAN UPDATE DATA
        var nbChild = parentObject.child.length;
        //NEED BECAUSE STRUCTURE CAN CHANGE WHEN CONCEPTOR ADD OR DELETE SLIDE
        for (var c = 0; c < nbChild; c++) {
            var child = parentObject.child[c];
            if (child.display == 'hidden') {
                continue;
            }
            nbSlides++;
            if (!session.progress.modules[parentObject.id].position?.details?.[child.id]?.end) {
                //console.log('norealised');
                continue;
            }
            position++;
        }
        //WHY ???
        /*if (session.last) {
            session.last.progress = explorationRate;
        }*/
        //CAN UPDATE SESSION
        session.progress.modules[parentObject.id].position.index.purcent =
            session.progress.modules[parentObject.id].progressEnd ? 100 : Math.round(position / nbSlides * 100);
        session.progress.modules[parentObject.id].position.index.fraction = position + ' / ' + nbSlides;
        session.progress.modules[parentObject.id].position.index.explorationRate = Math.round(position / nbSlides * 100);
    }
    //TAKE RECORDS
    purcentProgress = session.progress.modules[parentObject.id].progressEnd ?
        100 : session.progress.modules[parentObject.id].position?.index?.purcent ?? 0;
    explorationRate = session.progress.modules[parentObject.id].position?.index?.explorationRate ?? purcentProgress;

    //////////////SET END MODULE
    if (purcentProgress === 100) {
        session.progress.modules[parentObject.id].progressEnd = true;
    }
    //CHECK IF SCORE
    if (!session.progress.modules[parentObject.id].score?.index) {
        return {
            session: session,
            progress: {
                purcent: purcentProgress,
                label: purcentProgress + '% ' + stream.translator.getString('accomplished'),
                explorationRate: explorationRate
            }
        };
    }

    const totalPoint = session.progress.modules[parentObject.id].score.index.max ?? session.progress.modules[parentObject.id].score.index.fraction?.replace(' ', '').split('/')?.[1];
    let score;
    if (totalPoint && parseInt(totalPoint) > 0) {
        score = session.progress.modules[parentObject.id].score.index.purcent ?? 0;
        return {
            session: session,
            progress: {
                purcent: purcentProgress,
                score: score,
                scoreMax: parseInt(totalPoint),
                label: stream.translator.getString("scoreobtained")
                    + ' '
                    + score + ' %',
                explorationRate: explorationRate
            }
        }
    }
    return {
        session: session,
        progress: {
            purcent: purcentProgress,
            label: purcentProgress + '% ' + stream.translator.getString('accomplished'),
            explorationRate: explorationRate
        }
    };
};


const sendMessageToMobileInterface = function (action, data) {
    if (!typeof (data) == 'string') {
        data = JSON.stringify(data);
    }
    var messageString = { app: 'INTKNPlayer', action: action, arguments: data };
    parent.postMessage(messageString, '*');
    //parent.postMessage(messageString, window.location);
};
export {
    getValuesUrl, getUrlImg, getUserPreferences,
    notificationBubble, getMonthLabel, sortDateStartUp, getDayLabel,
    getSubDomain, getDomain, closeCurrentPopup, removeCurrentPopup, createPopupLogin,
    createPopupSignup, uniqueId, ucFirst, debounce, getMediaPlayer, donwloadMedia, isFile,
    addTimecodeAction, linkify, displayDate, setUrlImgByType, exportFromTextarea, importToTextarea,
    filterObject, computeProgressCourse, computeProgressModule, getNewSessionCourse, getNewSlide, sendMessageToMobileInterface
};