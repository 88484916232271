import "./src/js/editor";

// If in an iframe
if (window.self != window.top) {
    //SET GLOBAL USER OBJECT
    var userObject = false;
    var lang = "fr";
    getValuesUrl();
    if (getValues && getValues.language) {
        lang = getValues.language;
    }

    if (getValues && getValues.userObject) {
        userObject = JSON.parse(getValues.userObject);
    } else {
        if (!getCookie("login")) {
            if (
                !getValues ||
                !getValues.scope ||
                (getValues.scope != "library" && getValues.scope != "tutorials")
            ) {
                console.log("mustlogin");
            } else {
                var externalToken = "e3561504b2764ed07dcda513a9a7f3e0";
                var externalLogin = "nobody";
                userObject = {
                    login: externalLogin,
                    token: externalToken,
                    tokenPublic: externalToken,
                    language: lang,
                    firstName: externalLogin,
                    name: externalLogin,
                    sessionId: false,
                    domain: getDomainName(),
                };
                setCookie("loginExternal", externalLogin, "", "/", "", "");
                setCookie("tokenExternal", externalToken, "", "/", "", "");
                setCookie("lang", lang, "", "/", "", "");
            }
        } else {
            removeCookie("loginExternal", "", "/", "", "");
            removeCookie("tokenExternal", "", "/", "", "");
            if (getCookie("language") && !getValues.language) {
                lang = getCookie("language");
            }
            userObject = {
                login: getCookie("login"),
                token: getCookie("tokenSession"),
                tokenPublic: getCookie("tokenPublic"),
                language: lang,
                firstName: atob(getCookie("firstName")),
                sessionId: getCookie("sessionId"),
                name: atob(getCookie("name")),
                domain: getDomainName(),
            };
            let job = getCookie("job");
            if (job) {
                userObject.job = atob(getCookie("job"));
            }
            let organization = getCookie("job");
            if (organization) {
                userObject.organization = atob(getCookie("organization"));
            }
        }
    }

    var pdfViewer = document.createElement("intkn-pdfviewer");
    document.body.appendChild(pdfViewer);
    deviceDetection();
    var rootQuery = "/api2/object/download/";
    var query = {
        user: userObject.login.addSlashes(),
        action: "download",
        list: [
            {
                id: getValues.data,
                options: {
                    transfert: "inline",
                    resume: "on",
                    localStream: true,
                },
            },
        ],
        domain: ["appcataropro.ch"],
    };
    if (getValues.embeddedKey) {
        query.list[0].options.embeddedKey = getValues.embeddedKey;
    }
    var url =
        rootQuery +
        "?signature=1&login=" +
        userObject.login +
        "&key=" +
        userObject.token +
        "&query=" +
        encodeURIComponent(JSON.stringify(query));

    if (getValues) {
        if (getValues.renderType) {
            pdfViewer.setAttribute("render-type", getValues.renderType);
        }
        if (getValues.interfaceType) {
            pdfViewer.setAttribute("interface-type", getValues.interfaceType);
        }
        if (getValues.resolutionFactor) {
            pdfViewer.setAttribute(
                "resolution-factor",
                getValues.resolutionFactor
            );
        }
        if (getValues.downloadPolicy) {
            pdfViewer.setAttribute("download-policy", getValues.downloadPolicy);
        }
        if (getValues.navigationStyle) {
            pdfViewer.setAttribute(
                "navigation-style",
                getValues.navigationStyle
            );
        }
        if (getValues.navigationButtons) {
            pdfViewer.setAttribute(
                "navigation-buttons",
                getValues.navigationButtons
            );
        }
        if (getValues.data && !getValues.dataObject) {
            pdfViewer.setData({ id: getValues.data });
        }
        if (getValues.dataObject) {
            pdfViewer.setData(JSON.parse(getValues.dataObject));
        }
        if (getValues.actionBarEnabled != "false") {
            var actionbar = document.createElement("div");
            actionbar.setAttribute("id", "actionBar");
            actionbar.classList.add("actionBar");
            document.body.appendChild(actionbar);
            pdfViewer.actionbar = actionbar;
        }
        pdfViewer.setAttribute("url", url);
    }
}
